import React, { useState } from 'react';
import {ResetPassword} from "./ResetPassword/ResetPassword";
import {useDispatch, useSelector} from "react-redux";


function ResetPasswordContainer(){
    const [submitted, setSubmitted] = useState(false);

    const dispatch = useDispatch();
    const alert =  useSelector((state) => state.alert);

    return (
        <ResetPassword submitted={submitted}
                       alert={alert}
                       setSubmitted={setSubmitted}
                       dispatch={dispatch}/>
    )
}


export default ResetPasswordContainer;
