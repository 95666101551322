import React, {useEffect, useState} from 'react';
import {ListOrders} from "./ListOrders/ListOrders";
import { orderService } from '../../../services';
import {Loading} from "../../LoadingComponent";

function ListOrdersContainer(){

    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let unmounted = false;

        const fetchData = async () => {
            try {
                var users = JSON.parse(sessionStorage.getItem('user'))
                var params = {
                    "indirizzo_email": users.indirizzo_email,
                    "psw_md5": users.psw_md5,
                    "id_session": users.id_session
                }
                const res = await orderService.getOrders(params);

				if (!unmounted) {
                    if (res.ordini) {
                        setOrders(res.ordini);
                    }
                    setIsLoading(false);
                }

            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
        return () => { unmounted = true };
    }, []);

    if (isLoading){
        return <Loading />
    }
    else {
        return (
            <ListOrders orders={orders}/>
        )
    }
}

export default ListOrdersContainer;
