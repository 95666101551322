import React from 'react';
import {Link} from 'react-router-dom';
import {Control, Errors, Form} from "react-redux-form";

import '../../../../personal_lib/css/user-form.css';
import {Loading} from "../../../LoadingComponent";
import {alertActions} from "../../../../redux/alert/AlertCreators";
import {useDispatch} from "react-redux";

export function Register(props) {

    const required = (val) => val && val.length;
    const selected = (val) => {return val};
    const maxLength = (len) => (val) => !(val) || (val.length <= len);
    const minLength = (len) => (val) => (val) && (val.length >= len);
    const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);
    const hasUpperCase = (val) => /[A-Z]/.test(val);
    const hasLowerCase = (val) => /[a-z]/.test(val);
    const hasNumbers = (val) => /\d/.test(val);
    const hasNonalphas = (val) => /\W/.test(val);
    const passwordsMatch = ({ password, confirmPassword }) => {
        return password === confirmPassword;
    };

    const handleSubmit = (user) => {
        props.setSubmitted(true);
        props.register(user);
        props.resetFormRegister();
    }

    const handleSubmitFailed = (userForm) => {
        // logs form-level errors
        console.log(userForm);
    }
    const dispatch = useDispatch();

    return (
        <div className="user-form register-form">
            <div className="user-form-content">
                <div className="user-form-header">
                    <div className="user-form-switch">
                        <div className="user-form-switch-choise back-home">
                            <Link to="/home">Home</Link>
                        </div>

                        <div className="user-form-switch-choise">
                            <Link to="/login" onClick={() => dispatch(alertActions.error(''))}>Login</Link>
                        </div>
                        <div className="user-form-switch-choise active">
                            Registrati
                        </div>
                    </div>
                </div>
                <div className="register-header">
                    Registrati a
                    <span className="register-header-link"> shop.itarca.com</span>
                </div>
                <Form model="user"
                      onSubmit={(values) => handleSubmit(values)}
                      onSubmitFailed={(userForm) => handleSubmitFailed(userForm)}
                      validators={{
                          '': { passwordsMatch }}}
                      >
                    <div className="flex-outer">
                        <div className="user-form-input half">
                            <label htmlFor="nome">Nome</label>
                            <Control.text model=".nome" id="nome" name="nome"
                                          className="form-control"
                                          validators={{required, minLength: minLength(3)}}
                            />
                            <Errors className="text-danger" model=".nome"  show={{touched: true, focus: false}}
                                    messages={{
                                        required: 'Inserisci Nome',
                                        minLength: 'Nome non valido'
                                    }} />
                        </div>
                        <div className="user-form-input half">
                            <label htmlFor="cognome">Cognome</label>
                            <Control.text model=".cognome" id="cognome" name="cognome"
                                          className="form-control"
                                          validators={{required, minLength: minLength(3), maxLength: maxLength(15)}}
                            />
                            <Errors className="text-danger" model=".cognome"  show={{touched: true, focus: false}}
                                    messages={{
                                        required: 'Inserisci Cognome',
                                        minLength: 'Cognome non valido'
                                    }} />
                        </div>
                        <div className="user-form-input">
                            <label htmlFor="mail">Mail</label>
                            <Control.text type="email" model=".mail" id="mail" name="mail"
                                          className="form-control"
                                          validators={{required, validEmail}}
                            />
                            <Errors className="text-danger" model=".mail"  show={{touched: true, focus: false}}
                                    messages={{
                                        required: 'Inserisci Mail',
                                        validEmail: 'Mail non valida'
                                    }} />
                        </div>
                        <div className="user-form-input half">
                            <label htmlFor="password">Password</label>
                            <Control type="password" model=".password" id="password" name="password"
                                     className="form-control"
                                     validators={{required, minLength: minLength(8), hasUpperCase, hasLowerCase, hasNumbers, hasNonalphas}}
                            />
                            <Errors className="text-danger" model=".password"  show={{touched: true, focus: false}}
                                    messages={{
                                        required: 'Inserisci Password',
                                        minLength: 'Password troppo breve, minimo 8 caratteri ',
                                        hasUpperCase : 'Deve contenere almeno una maiuscola',
                                        hasLowerCase: "Deve contenere almeno una minuscola",
                                        hasNumbers: "Deve contenere almeno un numero",
                                        hasNonalphas: "Deve contenere almeno un carattere speciale"
                                    }} />
                        </div>

                        <div className="user-form-input half">
                            <label htmlFor="confirmPassword">Ripeti Password</label>
                            <Control type="password" model=".confirmPassword" id="confirmPassword" name="confirmPassword"
                                     className="form-control"
                                     validators={{required}}
                            />
                            <Errors className="text-danger" model=".confirmPassword"  show={{touched: true, focus: false}}
                                    messages={{
                                        required: 'Conferma Password'
                                    }} />
                            <Errors
                                className="text-danger"
                                model="user"
                                messages={{
                                    passwordsMatch: 'Passwords diversa',
                                }}
                                show={{touched: true, focus: false}}
                            />
                        </div>

                        <div className="user-form-input">
                            <label htmlFor="checkCondizioni" className="checkbox">Dichiaro di aver letto le <Link to="/condizioni" target="_blank" style={{textDecoration: "underline"}}>condizioni generali di vendita</Link></label>
                            <Control.checkbox model=".checkCondizioni" id="checkCondizioni" name="checkCondizioni"
                                     className="form-control"
                                     validators={{selected}}
                            />
                            <Errors className="text-danger" model=".checkCondizioni"  show={true}
                                    messages={{
                                        selected: 'Spunta obbligatoria'
                                    }} />
                        </div>

                        <div className="user-form-input">
                            <label htmlFor="checkPrivacy" className="checkbox">Accetto termini e condizioni della privacy</label>
                            <Control.checkbox model=".checkPrivacy" id="checkPrivacy" name="checkPrivacy"
                                     className="form-control"
                                     validators={{selected}}
                            />
                            <Errors className="text-danger" model=".checkPrivacy"  show={true}
                                    messages={{
                                        selected: 'Spunta obbligatoria'
                                    }} />
                        </div>


                        <button className="user-form-action" type="submit">
                            Registrati
                        </button>
						<div className="text-danger">
							<span>{props.alert.message}</span>
						</div>
                        {(props.submitted && !props.alert.message) ? <div style={{textAlign:"center"}}><Loading/></div> : ''}
					</div>
                </Form>
            </div>
        </div>
        )
}
