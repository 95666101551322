export const addressService = {
    getAddress,
    putAddress,
	removeAddress,
    setAddress
};

function getAddress(params) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    };
    console.log('api/get_indirizzi_salvato', requestOptions);

    return fetch('https://shop.itarca.com/api/get_indirizzi_salvato', requestOptions)
        .then(response => response.json())
        .catch(error =>  { console.log('get_indirizzi_salvato', error.message); });
}

function putAddress(params) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    };

    return fetch('https://shop.itarca.com/api/put_indirizzo_salvato', requestOptions)
        .then(response => response)
        .catch(error =>  console.log('put_indirizzo_salvato', error.message) );
}

function removeAddress(params) {
	const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    };

    return fetch('https://shop.itarca.com/api/delete_indirizzo_salvato', requestOptions)
        .then(response => response.json())
        .catch(error =>  { console.log('delete_indirizzo_salvato', error.message); });
}


function setAddress(params) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    };

    return fetch('https://shop.itarca.com/api/set_indirizzo_salvato', requestOptions)
        .then(response => response.json())
        .catch(error =>  { console.log('set_indirizzo_salvato', error.message); });
}
