import LineItem from "../../LineItemContainer/LineItemContainer";
import React from "react";

function RenderCart({cart, setStep, step}){


    const buttonStyle = {cursor: "pointer", fontSize: "16px", color:"#FFFFFF", background: '#0A6CA5', width: 200, padding: 16, textAlign: 'center', margin: '0 auto' };
    const divButtonStyle = {textAlign:"center", padding: "40px 40px 0 40px"};

    function getSum(total, product) {
        return total + (product.unit_price * product.quantity);
    }

    let line_items = cart.addedProducts.map((product) => {
        return (
            <LineItem
                key={product.barcode.toString()}
                line_item={product}
            />
        );
    });

    return(
        <div>
            <div className="cart_box">
                {cart.addedProducts.reduce(getSum,0) < 100?
                    (<div className="disclaimer_ordine">&Eacute; richiesto un ordine minimo di 100€ per concludere l'ordine. Il totale del tuo ordine attualmente è €{cart.addedProducts.reduce(getSum,0).toFixed(2)}</div>)
                    : (<></>)
                }
                    <div className="carrello">
                    <h2>Il tuo carrello</h2>
                    {cart.addedProducts.length > 0 ? (
                        <div>
                            <ul className="Checkout__line-items">
                                {line_items}
                            </ul>
                            <div className="Cart-info clearfix">
                                <div className="Cart-info__total">Totale</div>
                                <div className="Cart-info__pricing">
                                                <span className="pricing">
                                                    €{cart.addedProducts.reduce(getSum,0).toFixed(2)}
                                                </span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <h3> Nessun articolo presente</h3>
                    )}
                </div>
            </div>
            {cart.addedProducts.length > 0 && cart.addedProducts.reduce(getSum,0) >= 100? (
                <div style={divButtonStyle}>
                    <button style={ buttonStyle } onClick={ () => setStep(step + 1) }>Avanti</button>
                </div>) :
                (<></>)
            }
        </div>
)
}

export default RenderCart
