import React from 'react';
import Select from "react-select";

export const DropDownSubCategories = ({filtro, setFiltro, categories, selectedCategoryId, selectedSubCategory, setSelectedSubCategory }) => {

    const options = [];

    if (selectedCategoryId && selectedCategoryId.value){
        var category = categories[selectedCategoryId.value];
        options.push({value:'', label:'Tutte...'})
        for (const [key, value] of Object.entries(category.subcategories)) {
            options.push({value:key, label:value})}

        const handleChange = selectedOption => {
            setSelectedSubCategory(selectedOption)
            if (selectedOption.value){
                setFiltro(
                    {...filtro,
                    'categories': [parseInt(selectedCategoryId.value)],
                    'sub_categories': [parseInt(selectedOption.value)]})
            }
            else{
                    setFiltro(
                        {...filtro, 'categories': [parseInt(selectedCategoryId.value)]}
                    )
            }
        };

        return(
            <Select
                value={selectedSubCategory}
                options={options}
                onChange={handleChange}
                placeholder="Seleziona..."
            />
        )
    }
    else {
        return(
            <Select
                value={selectedSubCategory}
                options={options}
                placeholder="Seleziona..."
            />
            )
    }
};
