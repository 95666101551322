import React, {useEffect, useState} from 'react';
import {ProductDetail} from "./ProductDetail/ProductDetail";
import {addToCart} from "../../../redux/cart/ActionCreators";
import {connect} from "react-redux";
import {Loading} from "../../LoadingComponent";

function ProductDetailContainer(props){

    const [productDetail, setProductDetail] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('')

    useEffect(() => {
        setIsLoading(true)
        const fetchData = async () => {
            try {
                await fetch('https://shop.itarca.com/api/get_articolo?id_articolo=' + props.barcode)
                    .then(response => response.json())
                    .then(response => {
                        setProductDetail(response.article)
                        setMessage(response.message)
                        setIsLoading(false)
                        }
                    )
                    .catch(error =>  { console.log('get_articoli', error.message); });
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [props.barcode]);

    const [quantity, setQuantity] = useState(0);

    if (quantity < 0 ) {
        setQuantity(0)
    }

    const handleClickAddToCart = (product, quantity) => {
        props.addToCart(product, quantity)
        setQuantity(0);
    };

    if (productDetail && !isLoading)
        {
        return (<ProductDetail quantity={quantity} setQuantity={setQuantity} product={productDetail} handleClickAddToCart={handleClickAddToCart}/>)
    }
    else {
        if (isLoading)
            return <Loading />
        else
            return <div style={{textAlign:"center", padding:"100px", minHeight:"300px"}}> {message} </div>
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart
    }
};

const mapDispatchToProps = dispatch => ({
    addToCart: (product, quantity) => dispatch(addToCart(product, quantity)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailContainer);
