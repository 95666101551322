import React from 'react';
import './cart.css';
import LineItem from "../../LineItemContainer/LineItemContainer";
import {Link} from "react-router-dom";

export function Cart(props) {

    let line_items = props.cart.addedProducts.map((product) => {
        return (
            <LineItem
                key={product.barcode.toString()}
                line_item={product}
            />
        );
    });

    function getSum(total, product) {
        return total + (product.unit_price * product.quantity);
    }

    return (
        <div className={`Cart ${props.isCartOpen ? 'Cart--open' : ''}`}>
            <header className="Cart__header">
                <h2 >Il tuo carrello</h2>
                    <button className="Cart__close" id="cart_button" onClick={() => props.setIsCartOpen(false)}>
                        <i className="fa fa-times"></i>
                    </button>
            </header>
            <ul className="Cart__line-items">
                {line_items}
            </ul>
            <footer className="Cart__footer">
                <div className="Cart-info clearfix">
                    <div className="Cart-info__total">Totale</div>
                    <div className="Cart-info__pricing">
                        <span className="pricing">
                            €{props.cart.addedProducts.reduce(getSum,0).toFixed(2)}
                        </span>
                    </div>
                </div>
                <Link to="/checkout">
                    <button className="Cart__checkout button" onClick={() => props.setIsCartOpen(false)}>
                        Checkout
                    </button>
                </Link>
            </footer>
        </div>
    )};

