export const User = {
    nome: '',
    cognome: '',
    mail: '',
    password: '',
    confirmPassword: '',
    checkCondizioni: false,
    checkNewsLetter: '',
    checkPrivacy: false
};

export const UserLogin = {
    mail: '',
    password: ''
};

export const resetUser = {
    mail: ''
};

export const Information = {
    nome: '',
    cognome: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    checkNewsLetter: false
}
