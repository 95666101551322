import {combineReducers, createStore, applyMiddleware} from 'redux';
import {createForms} from 'react-redux-form';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import {CartReducer} from "../redux/cart/CartReducer";
import {UserReducer} from "../redux/user/UserReducer";
import {AuthenticationReducer} from "./user/authentication/AuthenticationReducer";
import {RegistrationReducer} from "./user/registration/RegistrationReducer";
import {AlertReducer} from "./alert/AlertReducer";
import {AddressReducer} from "./address/AddressReducer";
import {Information, resetUser, User, UserLogin} from "./form";
import {ProductReducer} from "./product/ProductReducer";

export const ConfigureStore = () => {

    const middlewares = [];

    if (process.env.NODE_ENV === `development`) {
        const { logger } = require(`redux-logger`);

        middlewares.push(logger);
    }

    const store = createStore(
        combineReducers(
            {
                products: ProductReducer,
                cart: CartReducer,
                users: UserReducer,
                authentication: AuthenticationReducer,
                registration: RegistrationReducer,
                alert: AlertReducer,
                addresses: AddressReducer,
                ...createForms({
                    user:User,
                    userLogin: UserLogin,
                    information: Information,
                    resetUser: resetUser
                })
            }),
    applyMiddleware(thunk, ...middlewares)
    );

    return store;
};
