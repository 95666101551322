import React from 'react';
import { Link } from 'react-router-dom';
import {Control, Errors, Form} from "react-redux-form";

import '../../../../personal_lib/css/user-form.css';
import {alertActions} from "../../../../redux/alert/AlertCreators";
import {useDispatch} from "react-redux";

export function Login(props) {

    const required = (val) => val && val.length;
    const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

    const handleSubmit = (userLogin) => {
        props.setSubmitted(true);
        props.login(userLogin.mail, userLogin.password);
        props.resetFeedbackLogin();
    };

    const handleSubmitFailed = (userForm) => {
        // logs form-level errors
        console.log(userForm);
    };

    const dispatch = useDispatch();
    return (
		<div className="user-form login-form">
			<div className="user-form-content">
				<div className="user-form-header">
					<div className="user-form-switch">
						<div className="user-form-switch-choise back-home">
							<Link to="/home">Home</Link>
						</div>

						<div className="user-form-switch-choise active">
							Login
						</div>
						<div className="user-form-switch-choise">
							<Link to="/register" onClick={() => dispatch(alertActions.error(''))}>Registrati</Link>
						</div>
					</div>
				</div>
				<div className="login-header">
					Accedi a
					<span className="login-header-link"> shop.itarca.com</span>
				</div>
            <Form model="userLogin"
                  onSubmit={(values) => handleSubmit(values)}
                  onSubmitFailed={(userForm) => handleSubmitFailed(userForm)}>
                <div className="flex-outer">
                    <div className="user-form-input">
                        <label htmlFor="mail">Mail</label>
                        <Control.text model=".mail" id="mail" name="mail"
                                      className="form-control"
                                      validators={{required, validEmail}}
                        />
                        <Errors className="text-danger" model=".mail"  show={{touched: true, focus: false}}
                                messages={{
                                    required: '',
                                    validEmail: 'Inserire mail valida '
                                }} />
                    </div>
                    <div className="user-form-input">
                        <label htmlFor="password">Password</label>
                        <Control type="password" model=".password"
                                 className="form-control"
                                 validators={{required}}
                        />
                        <Errors className="text-danger" model=".password" show={{touched: true, focus: false}}
                                messages={{
                                    required: 'Inserire Password'
                                }} />
                    </div>
                    <button className="user-form-action" type="submit">
                        Login
                    </button>
                    <div className="text-danger">
                        <span>{props.alert.message}</span>
                    </div>
                    <div className="user-form-input" style={{textAlign:"center", color:"#0A6CA5"}}>
                        <Link to="/resetPassword">Hai dimenticato la password?</Link>
                    </div>
                </div>
            </Form>
		</div>
		</div>
    )
}
