import React from 'react';
import Product from '../../ProductContainer/ProductContainer';
import './listproducts.css';

export function ListProducts(props) {
    return (
			props.products.map((product) => (
				<Product
					key={product.barcode} product={product}/>
			))
			);
}
