import React, { useState } from 'react';
import {Product} from "./Product/Product";
import {addToCart} from "../../../redux/cart/ActionCreators";
import {connect} from "react-redux";

function ProductContainer(props){
    const [quantity, setQuantity] = useState(0);

    if (quantity < 0 ) {
        setQuantity(0)
    }

    const handleClickAddToCart = (product, quantity) => {
        props.addToCart(product, quantity)
        setQuantity(0);
    };

    return (
        <Product quantity={quantity} setQuantity={setQuantity} product={props.product} handleClickAddToCart={handleClickAddToCart}/>
    )
}

const mapStateToProps = state => {
    return {
        cart: state.cart
    }
};

const mapDispatchToProps = dispatch => ({
    addToCart: (product, quantity) => dispatch(addToCart(product, quantity)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
