import React, {useState} from 'react';
import './header.css'
import $ from 'jquery';
import {Link} from "react-router-dom";

export function Header(props) {

    const [search_string, setSearch_string] = useState('')

    var classeuser;
    classeuser = (sessionStorage.user) ? "fa fa-user logged" : "fa fa-user";

    return (
    <div className="header">
        <section className="hd-main">
            <Link to="/home"><img className="hd-main-item logo" src="/assets/images/LogoHome.png" alt="Itarca"/></Link>
            <div className="hd-main-item search">
                <div className="search-bar">
                    <input
                        type="text"
                        className="input search-products"
                        placeholder="Cerca prodotti"
                        value={search_string} onChange={e => setSearch_string(e.target.value)}
                    />
                    <Link to={{pathname:"/products", state:{search_string}}} onClick={() => setSearch_string('')}><button className="icon fa fa-search"></button></Link>
                </div>
				<div className="search-bar-close only_mobile">
					<i className="fa fa-close"></i>
				</div>
            </div>
            <div className="hd-main-item icons">
                <div className="icon-list">
                    <div className="icon-item">
                        <button id="cart_button" onClick={() => props.setIsCartOpen(true)}>
                            {props.cart.addedProducts.length}<i className="fa fa-shopping-cart"></i>
                        </button>
                    </div>
                    <div className="icon-item">
                        <Link to="/user"><i className={classeuser}></i></Link>
                    </div>
					<div className="icon-item search-mobile-open-action only_mobile">
                        <i className="fa fa-search"></i>
                    </div>
                </div>
            </div>
        </section>
        <section className="hd-conditions only_desktop">
            <div className="condition-list">
                <Link to="/condizioni">
                    <div className="condition-item">
                    Condizioni generali
                    </div>
                </Link>
                <Link to="/spedizioni">
                    <div className="condition-item">
                        Spedizioni
                    </div>
                </Link>
            </div>
        </section>
    </div>
)}

$('#root').on('click', '.search-mobile-open-action', function() {
	if ($(window).innerWidth() <= 768) { // is mobile
		$('.hd-main-item.search').addClass('search-bar-mobile-open');
	}
});

$('#root').on('click', '.search-bar-close', function() {
	if ($(window).innerWidth() <= 768) { // is mobile
		$('.hd-main-item.search').removeClass('search-bar-mobile-open');
	}
});
