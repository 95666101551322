import React from 'react';
import {Link} from 'react-router-dom';
import {Control, Errors, Form} from "react-redux-form";

import '../../../../personal_lib/css/user-form.css';
import {Loading} from "../../../LoadingComponent";
import {userActions} from "../../../../redux/user/ActionCreators";

export function ResetPassword(props) {

    const required = (val) => val && val.length;
    const minLength = (len) => (val) => (val) && (val.length >= len);
    const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);


    const handleSubmit = (user) => {
        props.setSubmitted(true);
        props.dispatch(userActions.reset(user.mail))
    }

    return (
        <div className="user-form register-form">
            <div className="user-form-content">
                <div className="user-form-header">
                    <div className="user-form-switch">
                        <div className="user-form-switch-choise back-home">
                            <Link to="/home">Home</Link>
                        </div>

                        <div className="user-form-switch-choise">
                            <Link to="/login">Login</Link>
                        </div>
                        <div className="user-form-switch-choise active">
                            Registrati
                        </div>
                    </div>
                </div>
                <div className="register-header">
                    Resetta la password su
                    <span className="register-header-link"> shop.itarca.com</span>
                </div>
                <Form model="resetUser"
                      onSubmit={(values) => handleSubmit(values)}
                      >
                    <div className="flex-outer">
                        <div className="user-form-input">
                            <label htmlFor="mail">Mail</label>
                            <Control.text type="email" model=".mail" id="mail" name="mail"
                                          className="form-control"
                                          validators={{required, validEmail, minLength: minLength(3)}}
                            />
                            <Errors className="text-danger" model=".mail"  show={{touched: true, focus: false}}
                                    messages={{
                                        required: 'Inserisci Mail',
                                        validEmail: 'Mail non valida'
                                    }} />
                        </div>

                        <button className="user-form-action" type="submit">
                            Resetta
                        </button>
						<div className="text-danger">
							<span>{props.alert.message}</span>
						</div>
                        {(props.submitted && !props.alert.message) ? <div style={{textAlign:"center"}}><Loading/></div> : ''}
					</div>
                </Form>
            </div>
        </div>
        )
}
