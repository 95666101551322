import React from 'react';
import {Cart} from "./Cart/Cart";
import {connect} from "react-redux";

function CartContainer(props){
    return (
        <Cart isCartOpen={props.isCartOpen} setIsCartOpen={props.setIsCartOpen} cart={props.cart}/>
    )
}

const mapStateToProps = state => {
    return {
        cart: state.cart
    }
};

export default connect(mapStateToProps)(CartContainer);
