import React from 'react';
import {Ordine} from "./DetailOrder/DetailOrder";

function OrdineContainer(props){
    if (props.showModal) {
        return (
            <div className="ordine_container">
                <Ordine showModal={props.showModal} setShowModal={props.setShowModal} order={props.order}/>
            </div>
        )
    }
    else {
        return null;
    }
}

export default OrdineContainer;
