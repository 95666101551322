import React from 'react';
import PlacesAutocomplete from "../../../shared/PlacesAutoComplete";
import filterByType from "../../../../helpers/filter-address";

export function CheckoutAddressForm(props) {

    const handleChange= (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        props.formAddress.fields[name] = value;
        props.setFormAddress({...props.formAddress});

        props.shippingAddress[name] = value;
        props.setShippingAddress({...props.shippingAddress});


        if(name === 'address')
        {
            var addressComponent = event.target.component;
            if (addressComponent)
            {
                var state = addressComponent.filter((item) => filterByType(item, 'country'))[0].short_name;
                var region = addressComponent.filter((item) => filterByType(item, 'administrative_area_level_1'))[0].short_name;
                var province = addressComponent.filter((item) => filterByType(item, 'administrative_area_level_2'))[0].short_name;
                var city = addressComponent.filter((item) => filterByType(item, 'administrative_area_level_3'))[0].short_name;
                var cap = addressComponent.filter((item) => filterByType(item, 'postal_code'))[0].short_name;
                var street = addressComponent.filter((item) => filterByType(item, 'route'))[0].short_name;
                props.setShippingAddress({
                    ...props.shippingAddress,
                    state: state,
                    region: region,
                    province: province,
                    city: city,
                    cap: cap,
                    street: street
                })

            }
        }
    };

    return (
        <div className="form_box">
            <div className="flex-outer">
                    <div className="user-form-input half">
                        <label htmlFor="nome">Nome</label>
                        <input type="text" className="form-control" name="nome" value={props.formAddress.fields.nome} onChange={handleChange}  />
                        <span style={{color: "red"}}>{props.formAddress.error["nome"]}</span>
                    </div>
                    <div className="user-form-input half">
                        <label htmlFor="cognome">Cognome</label>
                        <input type="text" className="form-control" name="cognome" value={props.formAddress.fields.cognome} onChange={handleChange} />
                        <span style={{color: "red"}}>{props.formAddress.error["cognome"]}</span>
                    </div>
                    <div className="user-form-input half">
                        <label htmlFor="email">E-Mail</label>
                        <input type="text" className="form-control" name="email" value={props.formAddress.fields.email} onChange={handleChange} />
                        <span style={{color: "red"}}>{props.formAddress.error["email"]}</span>
                    </div>
                    <div className="user-form-input half">
                        <label htmlFor="address ">Indirizzo</label>
                        <PlacesAutocomplete value={props.formAddress.fields.address} onChange={handleChange} />
                        <span style={{color: "red"}}>{props.formAddress.error["address"]}</span>
                    </div>
                    <div className="user-form-input half">
                        <label htmlFor="house_nr">Nr. Civico</label>
                        <input type="text" className="form-control" name="house_nr" value={props.formAddress.fields.house_nr} onChange={handleChange}  />
                        <span style={{color: "red"}}>{props.formAddress.error["house_nr"]}</span>
                    </div>
					<div className="user-form-input half">
                        <label htmlFor="note_aggiuntive">Note aggiuntive</label>
						<textarea className="form-control" value={props.formAddress.fields.note_aggiuntive} name="note_aggiuntive" onChange={handleChange} >
						</textarea>
                        <span style={{color: "red"}}>{props.formAddress.error["note_aggiuntive"]}</span>
                    </div>
                <div className="user-form-input half">
                        <label htmlFor="phone">Telefono</label>
                        <input type="number" className="form-control" name="phone"  value={props.formAddress.fields.phone} onChange={handleChange} />
                        <span style={{color: "red"}}>{props.formAddress.error["phone"]}</span>
                </div>
            </div>
        </div>
    )};
