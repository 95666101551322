import React from 'react';
import './product.css';
import {Link} from "react-router-dom";
import {baseUrlImages} from "../../../shared/baseUrl";

function RenderType({product}){
    if (product.type === "C")
        return <img src="assets/images/congelato.png" alt="congelato" style={{verticalAlign: "bottom"}}/>;
    else if (product.type === "F")
        return <img src="assets/images/congelato.png" alt="congelato" style={{verticalAlign: "bottom"}}/>;
    else if (product.type === "S")
        return <img src="assets/images/congelato.png" alt="congelato" style={{verticalAlign: "bottom"}}/>;
    else
        return <></>
}


export function Product(props) {

    if (props.product) {

        var cover_image = props.product.images.filter(function(image) {
            return image.is_cover;
        })[0];

        return (
            <div className="card-item">
                <div className="card">
                    <div className="icon-item"><RenderType product={props.product} /> </div>
                    <Link to={{pathname: `product/${props.product.barcode}`}}>
                        <div className="card-image">
                            <img src={baseUrlImages + cover_image.url} alt={props.product.name}/>
                        </div>
                    </Link>
                    <div className="card-content">
                        <div className="card-title">{props.product.name}</div>
                        <p className="card-price">
                            € {props.product.unit_price.toFixed(2)}
                        </p>
                    </div>
	                    <div className= "shop">
	                        <div className="qta-buttons">
	                            <button id="login_button" className="qta-buttons-item qta-buttons-item-minus"
	                                    onClick={() => props.setQuantity(props.quantity - 1)}>
	                                <i className="fa fa-minus"></i>
	                            </button>
	                            <button id="login_button" className="qta-buttons-item qta-buttons-item-plus"
	                                    onClick={() => props.setQuantity(props.quantity + 1)}>
	                                <i className="fa fa-plus"></i>
	                            </button>
	                        </div>
	                        <div className="qta">
	                            <span className="qta-item-input">{props.quantity}</span>
	                        </div>
								<div className="total" >
		                            <button id="cart_button" className="cart-button"
		                                    onClick={() => props.handleClickAddToCart(props.product, props.quantity)}>
		                                <div className="total-item total-item-cart">
		                                    <i className="fa fa-shopping-cart"></i>
		                                </div>
		                                <div className="total-item">
		                                    € {(props.product.unit_price * props.quantity).toFixed(2)}
		                                </div>
		                            </button>
		                        </div>
	                    </div>
					<div className="article_tags_box">
						{props.product.coming_soon && <div className="article_tag" data-tag="coming_soon">In arrivo</div>}
						{props.product.is_peso_variabile && <div className="article_tag" data-tag="peso_variabile">Peso variabile</div>}
					</div>
                </div>
            </div>
        )
    } else {
         return (
             <div className="card-item">
             <div className="card segnaposto" >
                 <div className="card-image">
                     <div className="segnaposto_img"></div>
                 </div>
                 <div className="card-content">
                     <div className="card-title"></div>
                     <p className="card-text"></p>
                     <p className="card-price"></p>
                 </div>
                 <div className="shop">
                     <div className="qta-buttons">
                         <button id="login_button" className="qta-buttons-item qta-buttons-item-minus">
                             <i className="fa fa-minus"></i>
                         </button>
                         <button id="login_button" className="qta-buttons-item qta-buttons-item-plus">
                             <i className="fa fa-plus"></i>
                         </button>
                     </div>
                     <div className="qta">
                     </div>
                     <div className="total">
                         <div className="total-item total-item-cart">
                             <i className="fa fa-shopping-cart"></i>
                         </div>
                         <div className="total-item"></div>
                     </div>
                 </div>
             </div>
         </div>
         )
    }
};
