import {addressService} from "../../services/address.service";
import * as ActionTypes from './ActionTypes';
import {history} from "../../helpers/history";

export const addressActions = {
    fetchAddress,
    deleteAddress,
    setAddress,
    putAddress
};

function addAddresses (addresses) {
    return {
        type: ActionTypes.ADD_ADDRESSES,
        payload: addresses
    }
}

function addAddress (address){
    return{
        type: ActionTypes.ADD_ADDRESS,
        payload: address
    }
}

export const removeAddress = (address) => ({
    type: ActionTypes.REMOVE_ADDRESS,
    payload: address
});


export const addressLoading = () => ({
    type: ActionTypes.ADDRESS_LOADING
});

export const addressFailed = (errmess) => ({
    type: ActionTypes.ADDRESS_FAILED,
    payload: errmess
});

function fetchAddress (params) {
    return dispatch => {
        dispatch(addressLoading(true));
        addressService.getAddress(params)
            .then(response => response.addresses)
            .then(addresses => dispatch(addAddresses(addresses)))
            .catch(error => dispatch(addressFailed(error.message)));
    };
}

function putAddress (params)  {

    return dispatch => {
        dispatch(addressLoading(true));
        addressService.putAddress(params)
            .then(response => {
                    if (response.status == 200) {
                        return response;
                    } else {
                        var error = new Error('Error : ' + response.message);
                        error.response = response;
                        throw error;
                    }
                })
            .then(setTimeout(function() {dispatch(fetchAddress(params))}, 2000))
            .catch(error => {
                console.log(error)
                dispatch(addressFailed(error.message))
            })
    };
};


function setAddress (params)  {
    return dispatch => {
        dispatch(addressLoading(true));
        addressService.setAddress(params)
            .then(response => {
                if (response.status == 200) {
                    return response;
                } else {
                    var error = new Error('Error : ' + response.message);
                    error.response = response;
                    throw error;
                }
            })
            .then(setTimeout(function() {dispatch(fetchAddress(params))}, 2000))
            .catch(error => {
                dispatch(addressFailed(error.message))
            })
    };
};


function deleteAddress (params)  {

    return dispatch => {
        dispatch(addressLoading(true));
        addressService.removeAddress(params)
            .then(response => {
                    if (response.ok) {
                        return response;
                    } else {
                        var error = new Error('Error : ' + response.message);
                        error.response = response;
                        throw error;
                    }
                })
            .then(setTimeout(function() {dispatch(fetchAddress(params))}, 2000))
            .catch(error => {
                dispatch(addressFailed(error.message))
            })
    };
};
