import React from 'react';
import './checkout.css';
import  Stepper from "react-stepper-horizontal";
import RenderAddress from "./CheckoutAddress";
import RenderCart from "./CheckoutCart";
import RenderConfirm from "./CheckoutConfirm";

function Checkout(props) {

    return (
        <div>
            <Stepper steps={ [{title: 'Riepilogo carrello'}, {title: 'Indirizzo di Consegna'}, {title: 'Conferma Ordine'}] }
                     size={64} circleFontSize={32}
                     defaultColor="#0A6CA5"
                     completeColor="green"
                     activeStep={props.step}
                     defaultBarColor="#0A6CA5"
                     completeBarColor="green"/>
            <div className="content checkout">
                <div className="container-fluid">
                    {props.step === 0 ? (
                        <RenderCart cart={props.cart} setStep={props.setStep} step={props.step} />
                            ) : (props.step === 1 ?
                                (<RenderAddress setAddressFromExt={props.setAddressFromExt}
                                                authentication={props.authentication}
                                                setStep={props.setStep}
                                                step={props.step}
                                                shippingAddress={props.shippingAddress}
                                                setShippingAddress={props.setShippingAddress}
                                                formValue={props.formValue}
                                                checkForm={props.checkForm}
                                                setFormAddress={props.setFormAddress}
                                                formAddress={props.formAddress}
                                    />
                                    ) : <RenderConfirm
                                            cart={props.cart}
                                            authentication={props.authentication}
                                            setStep={props.setStep}
                                            step={props.step}
                                            shippingAddress={props.shippingAddress}
                                            deleteCart={props.deleteCart}/>
                    ) }
                </div>
            </div>
        </div>
    )}

export default Checkout;
