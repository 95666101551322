import React, { useState } from 'react';
import {Register} from "./Register/Register";
import { userActions } from '../../../redux/user/ActionCreators';
import {connect} from "react-redux";
import {actions} from "react-redux-form";

function RegisterContainer(props){
    const [submitted, setSubmitted] = useState(false);

    return (
        <Register submitted={submitted}
                  setSubmitted={setSubmitted}
                  registering={props.registering}
                  register={props.register}
                  alert={props.alert}
                  resetFormRegister={props.resetFormRegister}/>
    )
}

const mapStateToProps = state => {
    return {
        registering: state.registration,
        alert: state.alert
    }
};

const mapDispatchToProps = dispatch => ({
    register: (user) => dispatch(userActions.register(user)),
    resetFormRegister: () => { dispatch(actions.reset('user'))},
});


export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
