import React, { useState } from 'react';
import {Login} from "./Login/Login";
import { userActions } from '../../../redux/user/ActionCreators';
import {connect} from "react-redux";
import {actions} from 'react-redux-form';

function LoginContainer(props){
    const [submitted, setSubmitted] = useState(false);

    return (
        <Login
            submitted={submitted}
            setSubmitted={setSubmitted}
            login={props.login}
            loggingIn={props.loggingIn}
            resetFeedbackLogin={props.resetFeedbackLogin}
            alert={props.alert}/>
    )
}

const mapStateToProps = state => {
    return {
        loggingIn: state.authentication,
        alert: state.alert
    }
};

const mapDispatchToProps = dispatch => ({
    login: (mail, password) => dispatch(userActions.login(mail, password)),
    resetFeedbackLogin: () => { dispatch(actions.reset('userLogin'))},
});


export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
