import * as ActionTypes from './ActionTypes';

const initialState = {
    isLoading: true,
    errMess: null,
    addresses: []
};

const AddressReducer = (state = initialState, action) => {

    var address;

    switch (action.type) {
        case ActionTypes.ADD_ADDRESSES:
            return {...state, isLoading: false, errMess: null, addresses: action.payload};

        case ActionTypes.ADDRESS_FAILED:
            return {...state, isLoading: false, errMess: action.payload, addresses: []};

        case ActionTypes.ADD_ADDRESS: {
            address = action.payload;
            return { ...state, addresses: state.addresses.concat(address)};
        }

        case ActionTypes.REMOVE_ADDRESS:{
            address = action.payload;
            let new_items = state.addresses.filter(item=> address.indirizzo !== item.indirizzo)

            return{
                ...state,
                addresses: new_items
            }
        }

        default:
            return state;
    }
};
export {AddressReducer};
