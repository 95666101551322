import React from 'react';
import './footer.css'
import {Link} from "react-router-dom";

const Footer = () => {
    return(
        <div className="footer">
            <section className="ft-main">
                <div className="ft-main-item title">
                    <h2 className="ft-title">ITARCA</h2>
                </div>
            </section>
            <section className="ft-main">
                <div className="ft-main-item ft-main-item-left big">
                    <p>ITARCA S.p.A. Italo Argentina Carni Via XXV Aprile Ovest, 8/A - 42049 S. Ilario d&apos;Enza (RE)</p>
                    <p>Tel 0522 673090 / 0522 673497</p>
                    <p>Fax 0522 671212</p>
                    <p>Email: info@itarca.com</p>
                    <p>Reg. Impr. RE / CF / Partita Iva 00149620353</p>
                    <p>Cap. Soc. 310.000 Euro i.v. R.E.A. RE N. 108063 - C.C.P.N. 14029425 (aut.)</p>
                </div>
                <div className="ft-main-item ft-main-item-right">
                    <div className="ft-main-item-right-item condition-list">
                        <div className="condition-item">
                            <a target="_blank" rel="noopener noreferrer" href="http://www.itarca.com/flex/cm/pages/ServeBLOB.php/L/IT/IDPagina/18">
                                Privacy Policy
                            </a>
                        </div>
                        <div className="condition-item">
                            <a target="_blank" rel="noopener noreferrer" href="http://www.itarca.com/flex/cm/pages/ServeBLOB.php/L/IT/IDPagina/19">
                                Cookie
                            </a>
                        </div>
                        <Link to="/condizioni">
                        <div className="condition-item only_mobile">
                            Condizioni generali
                        </div>
                        </Link>
                        <Link to="/spedizioni">
                            <div className="condition-item only_mobile">
                                Spedizioni
                            </div>
                        </Link>
                    </div>
                    <div className="ft-main-item-right-item social-list">
                        <a href="https://www.facebook.com/Itarca" target="_blank" rel="noopener noreferrer">
                            <div className="social-item">
                                <i className="fa fa-facebook fa-lg"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
};

export default Footer;
