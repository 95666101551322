import React, {useState} from 'react';
import {Order} from "./Order/Order";

function OrderContainer({order}){

    const [showModal, setShowModal] = useState(false);

    const setShow = (stato) => {
        setShowModal(stato)
        if (stato)
        {
            document.body.style.overflow = "hidden"
        }
        else{
            document.body.style.overflow = "auto"
        }

    };

    return (
        <Order order={order}
               showModal={showModal} setShowModal={setShow}
        />
    )
}

export default OrderContainer;
