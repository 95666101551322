import React from 'react';
import './categories.css'
import {Link} from "react-router-dom";

export function Categories() {
    return (
        <div className="categories">
            <div className="grid-layout">
                <div className="span-1 grid-item">
                    <Link to={{pathname:"/products", state:{categories: {value : [1] , label: "CARNI"}}}}>
                        <div className="title">
                            <h3>CARNI</h3>
                        </div>
                    </Link>
                </div>
                <div className="span-2 grid-item">
                    <Link to={{pathname:"/products", state:{categories: {value : [16] , label: "VERDURE"}}}}>
                        <div className="title">
                            <h3>VERDURE</h3>
                        </div>
                    </Link>
                </div>
                <div className="span-3 grid-item">
                    <Link to={{pathname:"/products", state:{categories: {value : [11] , label: "PRODOTTI ITTICI"}}}}>
                        <div className="title">
                            <h3>PRODOTTI ITTICI</h3>
                        </div>
                    </Link>
                </div>
                <div className="span-4 grid-item">
                    <Link to={{pathname:"/products", state:{categories: {value : [9] , label: "PASTA"}}}}>
                        <div className="title">
                            <h3>PASTA</h3>
                        </div>
                    </Link>
                </div>
                    <div className="span-5 grid-item">
                        <Link to={{pathname:"/products", state:{categories: {value : [17] , label: "ALTRI PRODOTTI"}}}}>
                            <div className="title">
                                <h3>ALTRI PRODOTTI</h3>
                            </div>
                        </Link>
                    </div>
            </div>
        </div>
    )
};
