import React, {useState} from 'react';
import {Header} from "./Header/Header";
import {useSelector} from "react-redux";
import Cart from '../pages/CartContainer/CartContainer';

function HeaderContainer(){

    const cart = useSelector((state) => state.cart);
    const [isCartOpen, setIsCartOpen] = useState(false);

    return (
        <>
            <Header cart={cart} isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
            <Cart isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen}/>
        </>
)
}

export default HeaderContainer;
