import React from "react";
import './lineitem.css';
import {baseUrlImages} from "../../../shared/baseUrl";

export function LineItem(props) {

    const dynClasseQuantity = ((props.action_hidden) ? "Line-item__quantity-update nascosto" : "Line-item__quantity-update")
    const dynClasseRemove = ((props.action_hidden) ? "Line-item__remove nascosto" : "Line-item__remove")

    var cover_image = props.line_item.images.filter(function(image) {
        return image.is_cover;
    })[0];

    return (
        <li className="Line-item">
            <div className="Line-item__img">
                <img className="item__img" src={baseUrlImages + cover_image.url} alt={props.line_item.name}/>
            </div>
            <div className="Line-item__content">
                <div className="Line-item__content-row">
                    <span className="Line-item__title">
              {props.line_item.name}
            </span>
                </div>
                <div className="Line-item__content-row">
                    <div className="Line-item__quantity-container">
                        <button className={dynClasseQuantity}
                                onClick={() => props.subQuantity(props.line_item.barcode)}>-
                        </button>
                        <span className="Line-item__quantity">{props.line_item.quantity}</span>

                        <button id="cart_button" className={dynClasseQuantity} onClick={() => props.addQuantity(props.line_item.barcode)}>
                            <i className="fa fa-plus"></i>
                        </button>

                    </div>
                    <span className="Line-item__price">
              € {(props.line_item.quantity * props.line_item.unit_price).toFixed(2)}
            </span>
                    <button className={dynClasseRemove}
                            onClick={() => props.removeFromCart(props.line_item.barcode)}>
                        <i className="fa fa-times"></i>
                    </button>
                </div>
            </div>
        </li>
    );
}
