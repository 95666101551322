import React from 'react';
import './orderproduct.css';
import {baseUrlImages} from "../../../../../shared/baseUrl";

export function OrderProduct(props) {

    return (
        <div className="card-item order-product">
            <div className="card" >
                <div className="card-image">
                    <img src={`${baseUrlImages + props.product.image_url}`} alt={props.product.name}/>
                </div>
                <div className="card-content">
                    <div className="card-title">{props.product.name}</div>
                    <p className="card-text">
                        {props.product.unit_meausure}
                    </p>
                </div>
                <div className="shop">
                    <div className="qta">
                        <span className="qta-item-input">{props.product.qta}</span>
                    </div>
                    <div className="total">
                        <div className="total-item">
                            <div className="total-price-item">
                                PREZZO: € {(props.product.unit_price * props.product.qta).toFixed(2)}
                            </div>

                            <div className="unit-price-item">
                                (€ {props.product.unit_price} x unità)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)}
