import React, {useState} from 'react';
import {Home} from "./Home/Home";

function HomeContainer(){
    const [PopUp, setPopUp] = useState({'open': false})

    return (
        <Home PopUp={PopUp} setPopUp={setPopUp}/>
    )
}

export default HomeContainer;
