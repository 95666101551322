import React from "react";
import usePlacesAutocomplete, {
    getGeocode
} from "use-places-autocomplete";

const PlacesAutocomplete = (props) => {

    const {
        ready,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: {country: "it"}
        },
        debounce: 300,
    });


    const handleInput = (e) => {
        // Update the keyword of the input element
        setValue(e.target.value);
        props.onChange(e);
    };

    const handleSelect = ({ description }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter as "false"
        setValue(description, false);

        clearSuggestions();

        var components;
        // Get description with geocoding
        getGeocode({ address: description })
            .then((results) => {
                components = results[0].address_components;
                props.onChange(
                    {'target':
                        {
                            'name': 'address',
                            'value': description,
                            'component': components
                        }
                    })
            })
            .catch((error) => {
                console.log("😱 Error: ", error);
            });
    };

    const renderSuggestions = () =>
        data.map((suggestion, index) => {
            const {
                id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            var key = ((id) ? id : index)
            return (
                <li key={key} onClick={handleSelect(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    return (
        <>
            <input
                className="form-control" name="address"
                value={props.value}
                onChange={handleInput}
                disabled={(!ready || props.disabled)}
            />
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            {status === "OK" && <ul>{renderSuggestions()}</ul>}
        </>
    );
};

export default PlacesAutocomplete;
