import React from 'react';
import Order from '../../OrderContainer/OrderContainer';
import './listorder.css';

export function ListOrders({orders}) {

    if (orders.length > 0)
        return (
                <div className="ordini">
                    {orders.map((order, index) => (
                    <Order
                        key={index} order={order}/>
                ))}
                </div>
        );
    else
        return <div className="ordini"><p style={{margin:"auto"}}>Nessun risultato trovato</p></div>
}
