import React from 'react';

import Product from '../../ProductContainer/ProductContainer';
import './sliderproducts.css';
import Slider from "react-slick";

export function SliderProducts({products}) {
	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		initialSlide: 0,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 1480,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 1150,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	return (
		<Slider {...settings} className="react-slick-theme">
		{
			((products.length > 0) ? products.map((product) => (
				<Product
					key={product.barcode} product={product}/>
			)) : [1,2,3,4,5].map((val, index) => (
					<Product key={index} />))
				)
			}
		</Slider>
    );
};
