import React from 'react';
import {LineItem} from "./LineItem/LineItem";
import {addQuantity, addToCart, removeFromCart, subQuantity} from "../../../redux/cart/ActionCreators";
import {connect} from "react-redux";

function LineItemContainer(props){

    var action_hidden = ((props.action_hidden) ? true : false );

    return (
        <LineItem line_item={props.line_item}
                  addToCart={props.addToCart}
                  removeFromCart={props.removeFromCart}
                  addQuantity={props.addQuantity}
                  subQuantity={props.subQuantity}
                  action_hidden={action_hidden}/>
    )
}

const mapStateToProps = state => {
    return {
        cart: state.cart
    }
};

const mapDispatchToProps = dispatch => ({
    addToCart: (product, quantity) => dispatch(addToCart(product, quantity)),
    removeFromCart: (id) => dispatch(removeFromCart(id)),
    addQuantity: (id) => dispatch(addQuantity(id)),
    subQuantity: (id) => dispatch(subQuantity(id)),
});


export default connect(mapStateToProps, mapDispatchToProps)(LineItemContainer);
