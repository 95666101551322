import React from 'react';
import {Categories} from "./Categories/Categories";

function CategoriesContainer(){
    return (
        <Categories />
    )
}

export default CategoriesContainer;
