import React, {useEffect, useState} from 'react';
import {ListAddress} from "./ListAddress/ListAddress";
import {connect} from "react-redux";
import {addressActions} from "../../../redux/address/ActionCreators";

function ListAddressContainer({fromCheckout, fetchAddress, addresses, putAddress, setAddress, deleteAddress, shippingAddress, setShippingAddress}){

    const fromCheckoutB = ((fromCheckout) ? fromCheckout : "");
    const [search, setSearch] = useState(0)

    useEffect(() => {

        var users = JSON.parse(sessionStorage.getItem('user'));
        var params = {
            "indirizzo_email": users.indirizzo_email,
            "psw_md5": users.psw_md5,
            "id_session": users.id_session
        };

       fetchAddress(params);

    }, [fetchAddress, search])


    return (
        <ListAddress addresses={addresses}
                     search={search}
                     setSearch={setSearch}
                     putAddress={putAddress}
                     setAddress={setAddress}
                     deleteAddress={deleteAddress}
                     fromCheckout={fromCheckoutB}
                     shippingAddress={shippingAddress}
                     setShippingAddress={setShippingAddress}
                     fetchAddress={fetchAddress}/>
    )
}

const mapStateToProps = state => {
    return {
        addresses: state.addresses
    }
};

const mapDispatchToProps = dispatch => ({
    fetchAddress: (params) => { dispatch(addressActions.fetchAddress(params))},
    putAddress: (params) => dispatch(addressActions.putAddress(params)),
    setAddress: (params) => dispatch(addressActions.setAddress(params)),
    deleteAddress: (params) => dispatch(addressActions.deleteAddress(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListAddressContainer);
