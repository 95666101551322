import React from 'react';
import {DropDownCategories} from '../../DropDownCategories'
import {DropDownSubCategories} from '../../DropDownSubCategories'
import {FiltriIcons} from "../../FiltriIcons";

function ProductFilterContainer({
                                    categories,
                                    filtro,
                                    setFiltro,
                                    selectedCategoryId,
                                    setSelectedCategoryId,
                                    selectedSubCategory,
                                    setSelectedSubCategory,
                                    classeSecco,
                                    setClasseSecco,
                                    classeFresco,
                                    setClasseFresco,
                                    classeCongelato,
                                    setClasseCongelato
                                }){


    return (
        <>
            <div className="filtri">
                <div className="categoria">
                    <label>CATEGORIE</label>
                    <div className="dropdown">
                        <DropDownCategories
                            filtro={filtro}
                            setFiltro={setFiltro}
                            categories={categories}
                            setSelectedCategoryId={setSelectedCategoryId}
                            selectedCategoryId={selectedCategoryId}
                            setSelectedSubCategory={setSelectedSubCategory}/>
                    </div>
                </div>
                <div className="sottocategoria">
                    <label>SOTTO CATEGORIE</label>
                    <div className="dropdown">
                        <DropDownSubCategories setFiltro={setFiltro}
                                               categories={categories}
                                               selectedCategoryId={selectedCategoryId}
                                               setSelectedSubCategory={setSelectedSubCategory}
                                               selectedSubCategory={selectedSubCategory}/>
                    </div>
                </div>
                <div className="filtri-icons">
                    <FiltriIcons filtro={filtro }setFiltro={setFiltro}
                                 classeCongelato={classeCongelato} setClasseCongelato={setClasseCongelato}
                                 classeSecco={classeSecco} setClasseSecco={setClasseSecco}
                                 classeFresco={classeFresco} setClasseFresco={setClasseFresco}
                                 setSelectedCategoryId={setSelectedCategoryId}
                                 setSelectedSubCategory={setSelectedSubCategory}
                    />
                </div>
            </div>
        </>
    )
}

export default (ProductFilterContainer);
