import React from 'react';
import {Control, Errors, Form} from "react-redux-form";

import './information.css';

export function Information(props) {

    const required = (val) => val && val.length;
    const maxLength = (len) => (val) => !(val) || (val.length <= len);
    const minLength = (len) => (val) => !(val) || (val.length >= len);
    const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);
    const hasUpperCase = (val) => !(val) || /[A-Z]/.test(val);
    const hasLowerCase = (val) => !(val) || /[a-z]/.test(val);
    const hasNumbers = (val) => !(val) || /\d/.test(val);
    const hasNonalphas = (val) => !(val) || /\W/.test(val);
    const passwordsMatch = ({ password, confirmPassword }) => {
        return password === confirmPassword;
    };

    const handleSubmit = (information) => {
        props.setInformation(information)
    }

    return (
        <Form model="information"
              onSubmit={(values) => handleSubmit(values)}
              validators={{
                  '': { passwordsMatch }}}
        >
            <div className="flex-outer container-fluid-small">
                <div className="user-form-input half">
                    <label htmlFor="nome">Nome</label>
                    <Control.text model=".nome" id="nome" name="nome"
                                  className="form-control"
                                  validators={{required, minLength: minLength(3), maxLength: maxLength(15)}}
                    />
                </div>
                <div className="user-form-input half">
                    <label htmlFor="cognome">Cognome</label>
                    <Control.text model=".cognome" id="cognome" name="cognome"
                                  className="form-control"
                                  validators={{required, minLength: minLength(3), maxLength: maxLength(15)}}
                    />
                </div>
                <div className="user-form-input">
                    <label htmlFor="email">Mail</label>
                    <Control.text type="email" model=".email" id="email" name="email"
                                  className="form-control"
                                  disabled={true}
                                  validators={{required, minLength: minLength(3), validEmail}}
                    />
                </div>

                <div className="user-form-input half">
                    <label htmlFor="password">Password</label>
                    <Control type="password" model=".password" id="password" name="password"
                             className="form-control"
                             validators={{minLength: minLength(8), hasUpperCase, hasLowerCase, hasNumbers, hasNonalphas}}
                    />
                    <Errors className="text-danger" model=".password"  show={{touched: true, focus: false}}
                            messages={{
                                minLength: 'Password troppo breve, minimo 8 caratteri ',
                                hasUpperCase : 'Deve contenere almeno una maiuscola',
                                hasLowerCase: "Deve contenere almeno una minuscola",
                                hasNumbers: "Deve contenere almeno un numero",
                                hasNonalphas: "Deve contenere almeno un carattere speciale"
                            }} />
                </div>

                <div className="user-form-input half">
                    <label htmlFor="confirmPassword">Ripeti Password</label>
                    <Control type="password" model=".confirmPassword" id="confirmPassword" name="confirmPassword"
                             className="form-control"
                    />
                    <Errors
                        className="text-danger"
                        model="information"
                        messages={{
                            passwordsMatch: 'Passwords diversa',
                        }}
                        show={{touched: true, focus: false}}
                    />
                </div>

                {/*
                <div className="user-form-input">
                    <label htmlFor="phone">phone</label>
                    <Control.text model=".phone" id="phone" name="phone"
                                  className="form-control"
                                  validators={{required, minLength: minLength(3), isNumber}}
                    />
                </div>
*/}
                <button className="user-form-action" type="submit">
                    Aggiorna Informazioni
                </button>
            </div>
        </Form>
)
}
