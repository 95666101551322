import React from 'react';
import './address.css';
import PlacesAutocomplete from "../../../shared/PlacesAutoComplete";
import filterByType from "../../../../helpers/filter-address";

export function Address(props) {

    const required = (val) => val && val.length;
    const validPhone = (val) => /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\/0-9]*$/i.test(val);

    const handleClickModify = () => {
        props.setDisabled(false)
        setError("nome",  '')
        setError("address",  '')
        setError("cognome",  '')
        setError("phone",  '')
        setError("house_nr",  '')
		setError("note_aggiuntive",  '')
    };

    const resetFormAddress = () => {
        props.setFormAddress(
            {
                'fields': {
					id: '',
                    nome: '',
                    cognome: '',
                    address: '',
                    phone: '',
                    house_nr: '',
					note_aggiuntive: '',
                    is_shipping_address: '',
                    state: '',
                    region:'',
                    province: '',
                    city: '',
                    cap:'',
                    street:'',
                },
                'error': {
                    nome: '',
                    cognome: '',
                    email: '',
                    address: '',
                    phone: '',
                    house_nr: '',
					note_aggiuntive: '',
                }
            })
    }

    const setError = (field, msg) => {
        props.setFormAddress((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields},
            error: {
                ...prevState.error, [field]: msg}
        }))
    }

    const handleSubmit = async () => {
        let fields = props.formAddress.fields;

        var errore= false;
        if(!required(fields.nome))
            setError("nome",  'Non valido')
        else
            setError("nome",  '')
        if(!required(fields.address) || !required(fields.state) || !required(fields.region)
            || !required(fields.province) || !required(fields.city) || !required(fields.cap.toString())
            || !required(fields.street))
            setError("address",  'Inserire indirizzo completo')
        else
            setError("address",  '')
        if(!required(fields.cognome))
            setError("cognome",  'Non valido')
        else
            setError("cognome",  '')
        if(!required(fields.house_nr))
            setError("house_nr", 'Non valido')
        else
            setError("house_nr",  '')

        if(!required(fields.phone) || validPhone(fields.phone))
            setError("phone", 'Non valido')
        else
            setError("phone",  '')


        if(
            !required(fields.nome) ||
            !required(fields.address) || !required(fields.state) || !required(fields.region)
            || !required(fields.province) || !required(fields.city) || !required(fields.cap.toString())
            || !required(fields.street) ||   !required(fields.cognome)  || !required(fields.phone) ||
            !required(fields.house_nr)
        ){
            errore=true
        }

        if (!errore)
        {
            var users = JSON.parse(sessionStorage.getItem('user'))
            var params = {
                "indirizzo_email": users.indirizzo_email,
                "psw_md5": users.psw_md5,
                "id_session": users.id_session,
                "address": {
                    "nome": fields.nome,
                    "cognome": fields.cognome,
                    "state": fields.state,
                    "region":fields.region,
                    "province": fields.province,
                    "city": fields.city,
                    "cap": fields.cap,
                    "street":fields.street,
                    "house_nr" : fields.house_nr,
					"note_aggiuntive" : fields.note_aggiuntive,
                    "address": fields.address,
                    "phone": fields.phone
                }
            }

            if(!props.address)
                await props.putAddress(params)
            else
            {
                await props.setAddress({...params, address: {...params.address, "id" : props.address.id}})
            }

			resetFormAddress();
			//props.setSearch(props.search + 1)
            props.setDisabled(true);
        }
        //await props.fetchAddress(params)
    }

    const handleDeleteSubmit = async () => {
		var users = JSON.parse(sessionStorage.getItem('user'))
		var params = {
			"indirizzo_email": users.indirizzo_email,
			"psw_md5": users.psw_md5,
			"id_session": users.id_session,
			"address_id": props.formAddress.fields.id
		}

        await props.deleteAddress(params);

		resetFormAddress();
//		props.setSearch(props.search + 1)
		props.setDisabled(true);
    }

    const checkDisable = () => props.disabled;

    const handleChange= (event) => {
        const target = event.target;
        const name = target.name;

        if (name === "is_shipping_address"){
            var users = JSON.parse(sessionStorage.getItem('user'))

            props.setShippingAddress({
                ...props.shippingAddress,
                address: props.address.address,
                state: props.address.state,
                region: props.address.region,
                province: props.address.province,
                city: props.address.city,
                cap: props.address.cap,
                street: props.address.street,
                cognome: props.address.cognome,
                nome: props.address.nome,
                house_nr: props.address.house_nr,
				note_aggiuntive: props.address.note_aggiuntive,
                email: users.indirizzo_email,
                phone: props.address.phone
            })
        }
        const value = ((name === "is_shipping_address") ? target.checked : target.value);
        props.formAddress.fields[name] = value;
        props.setFormAddress({...props.formAddress});

        if(name==='address')
        {
            var addressComponent = event.target.component;
            if (addressComponent)
            {
                props.formAddress.fields['state'] = addressComponent.filter((item) => filterByType(item, 'country'))[0].short_name;
                props.formAddress.fields['region'] = addressComponent.filter((item) => filterByType(item, 'administrative_area_level_1'))[0].short_name;
                props.formAddress.fields['province'] = addressComponent.filter((item) => filterByType(item, 'administrative_area_level_2'))[0].short_name;
                props.formAddress.fields['city'] = addressComponent.filter((item) => filterByType(item, 'administrative_area_level_3'))[0].short_name;
                props.formAddress.fields['cap'] = parseInt(addressComponent.filter((item) => filterByType(item, 'postal_code'))[0].short_name);
                props.formAddress.fields['street'] = addressComponent.filter((item) => filterByType(item, 'route'))[0].short_name;
                props.setFormAddress({
                    ...props.formAddress
                })
            }
        }
    };
    const classCustom = ((props.fromCheckout && props.formAddress.fields.address) ? "address-form-input checkbox" : 'address-form-input checkbox nascosto');

    return (
    <div className="card-address">
    <div className="card-item">
        <div className="card" >
            <div className="card-header">
            </div>
                <div className="card-content">
					<div className={classCustom}>
						<input type="hidden" className="form-control" name="id"  value={props.id} onChange={handleChange}/>
					</div>
                    <div className={classCustom}>
                        <input type="checkbox" className="form-control" name="is_shipping_address"  checked={props.address_to_ship} onChange={handleChange}/>
                        <label className="checkbox" >Indirizzo attivo</label>
                    </div>
                    <div className="address-form-input">
                        <label htmlFor="nome">Nome</label>
                        <input type="text" className="form-control" name="nome" value={props.formAddress.fields.nome} onChange={handleChange} disabled={checkDisable()} />
                        <span style={{color: "red"}}>{props.formAddress.error["nome"]}</span>
                    </div>
                    <div className="address-form-input">
                        <label htmlFor="cognome">Cognome</label>
                        <input type="text" className="form-control" name="cognome" value={props.formAddress.fields.cognome} onChange={handleChange} disabled={checkDisable()} />
                        <span style={{color: "red"}}>{props.formAddress.error["cognome"]}</span>
                    </div>
                    <div className="address-form-input">
                        <label htmlFor="indirizzo">Indirizzo</label>
                        <PlacesAutocomplete value={props.formAddress.fields.address} onChange={handleChange} disabled={props.disabled}/>
                        <span style={{color: "red"}}>{props.formAddress.error["address"]}</span>
                    </div>
                    <div className="address-form-input">
                        <label htmlFor="house_nr">Nr. Civico</label>
                        <input type="text" className="form-control" name="house_nr" value={props.formAddress.fields.house_nr} onChange={handleChange} disabled={checkDisable()} />
                        <span style={{color: "red"}}>{props.formAddress.error["house_nr"]}</span>
                    </div>
                    <div className="address-form-input">
                        <label htmlFor="phone">Telefono</label>
                        <input type="text" className="form-control" name="phone"  value={props.formAddress.fields.phone} onChange={handleChange} disabled={checkDisable()}/>
                        <span style={{color: "red"}}>{props.formAddress.error["phone"]}</span>
                    </div>
					<div className="address-form-input">
                        <label htmlFor="note_aggiuntive">Note aggiuntive</label>
                        <textarea className="form-control" name="note_aggiuntive" onChange={handleChange} disabled={checkDisable()}  value={props.formAddress.fields.note_aggiuntive}>
						</textarea>
                        <span style={{color: "red"}}>{props.formAddress.error["note_aggiuntive"]}</span>
                    </div>
                </div>
                <div className="address-action">
                        {
                            ((props.disabled) ?
                                <button id="modify_address" className="address-button" onClick={(address) => handleClickModify(address)}>
                                    <div className="address-action-text">
                                        Modifica
                                    </div>
                                </button> :
                                <button id="modify_address" type="submit" className="address-button" onClick={() => handleSubmit()}>
                                    <div className="address-action-text">
                                        Salva
                                    </div>
                                </button>
                            )
                        }
                        {
                            ((props.id >= 0) ?
                                <button id="remove_address" className="address-button"  onClick={() => handleDeleteSubmit()}>
                                <div className="address-action-text">
                                    Rimuovi
                                </div>
                                </button> : <div></div>)
                        }
                </div>
            </div>
        </div>
    </div>
    )};
