import React from 'react';
import Address from '../../AddressContainer/AddressContainer';
import './listaddress.css';

export function ListAddress(props) {
        return (
            <div className="indirizzi">
                <Address putAddress={props.putAddress}
                         deleteAddress={props.deleteAddress}
                         fromCheckout={props.fromCheckout}
                         shippingAddress={props.shippingAddress}
                         setShippingAddress={props.setShippingAddress}
                         search={props.search}
                         setSearch={props.setSearch}
                         setAddress={props.setAddress}
                         fetchAddress={props.fetchAddress}/>
                    {props.addresses.addresses.map((address, index) => (
                        <Address
                            key={index} id={index}
                            search={props.search}
                            setSearch={props.setSearch}
                            address={address}
                            putAddress={props.putAddress}
                            deleteAddress={props.deleteAddress}
                            fromCheckout={props.fromCheckout}
                            shippingAddress={props.shippingAddress}
                            setShippingAddress={props.setShippingAddress}
                            setAddress={props.setAddress}
                            fetchAddress={props.fetchAddress}/>
                    ))
                }
            </div>
        );
}
