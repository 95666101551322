/* eslint react/prop-types: 0 */

import React from 'react';
import './order.css';
import {Link} from "react-router-dom";
import DetailOrder from "../../DetailOrderContainer/DetailOrderContainer"

export function Order(props) {
	var totale = 0
	var nr_articoli = 0;

	for (var i=0; i<props.order.articoli.length; i++) {
		totale += props.order.articoli[i]['unit_price'] * props.order.articoli[i]['qta'];
		nr_articoli +=  props.order.articoli[i]['qta'];
	}

	totale = totale.toFixed(2)

    return (
        <div>
        <div className="card-order">
            <div className="card-item">
                <div className="card" >
                    <Link to={`/product/${props.order.id}`} >
                    </Link>
                    <div className="card-content">
                        <div className="order-attribute">
                            <strong>Ordine nr:</strong> {props.order.id}
                        </div>
                        <div className="order-attribute">
                            <strong>Nr. articoli:</strong> {nr_articoli}
                        </div>
                        <div className="order-attribute">
                            <strong>Data ordine:</strong> {props.order.date_start}
                        </div>
                        <div className="order-attribute">
                            <strong>Data arrivo:</strong>
                        </div>
                        <div className="order-attribute long-and-truncated">
                            <strong>Indirizzo spedizione:</strong> {props.order.address.street},{props.order.address.city}
                        </div>
                        <div className="detail-order">
                            <strong>PRODOTTI</strong>
                        </div>
                        <div className="long-and-truncated">
                            {props.order.articoli.map(function(product){
                                return product.name;
                            }).join(",")}
                        </div>
                        <div className="order-total">
                            <strong>TOTALE</strong>
                        </div>
                    </div>

                    <div className="order-footer">
                        <div className="order-total-price">
                            € {totale}
                        </div>
                        <div className="detail-btn">
                            <button className="order-detail-action" onClick={() => props.setShowModal(true)}>
                                Dettagli
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <DetailOrder showModal={props.showModal} setShowModal={props.setShowModal} order={props.order}/>
        </div>
    )};
