import LineItem from "../../LineItemContainer/LineItemContainer";
import React, {useState} from "react";
import { orderService } from '../../../../services';
import {Loading} from "../../../LoadingComponent";
import {history} from "../../../../helpers/history";
import PopUpOrdine from "../../../PopUpOrdine";

function RenderCart({cart, authentication, setStep, step, shippingAddress, deleteCart}){
    const [isLoading, setIsLoading] = useState(false);
    const [PopUp, setPopUp] = useState({'open': false, 'message': ''})

    const buttonStyle = {cursor: "pointer", fontSize: "16px", color:"#FFFFFF", background: '#0A6CA5', width: 200, padding: 16, textAlign: 'center', margin: '0 auto' };
    const buttonStyleConfirm = {textAlign:"center", cursor: "pointer", fontSize: "22px", color:"#FFFFFF", background: '#0A6CA5', width: 200, padding: 22, margin: '0 auto' };
    const divButtonStyle = {textAlign:"center", padding: "40px 40px 0 40px"};
    const divRecipe = {display:"flex", flexDirection: "row", flexWrap: "wrap"};

    function getSum(total, product) {
        return total + (product.unit_price * product.quantity);
    }

    async function inviaOrdine(){
        try {
            setIsLoading(true);
            var utenteOrdine;
            if(!authentication.loggedIn){
                utenteOrdine = {
                    "user_anonymous": {
                        "nome": shippingAddress.nome,
                        "cognome": shippingAddress.cognome,
                        "indirizzo_email": shippingAddress.email,
                        "numero_cellulare": shippingAddress.phone,
                    }
                }
            }
            else{
                var users = JSON.parse(sessionStorage.getItem('user'))
                utenteOrdine = {
                    "user_logged": {
                        "indirizzo_email": users.indirizzo_email,
                        "psw_md5": users.psw_md5,
                        "id_session": users.id_session,
                    }
                }
            }

            var addressOrdine = {
                "address" : {
					"nome": shippingAddress.nome,
					"cognome": shippingAddress.cognome,
                    "state": shippingAddress.state,
                    "region": shippingAddress.region,
                    "province": shippingAddress.province,
                    "city": shippingAddress.city,
                    "cap": parseInt(shippingAddress.cap),
                    "street": shippingAddress.street,
                    "house_nr": shippingAddress.house_nr,
					"note_aggiuntive": shippingAddress.note_aggiuntive,
                    "address": shippingAddress.address,
                    "phone": shippingAddress.phone
                }
            };

            var articoliOrdine = {
                "articles" : cart.addedProducts
            };

            var paramsOrdine = Object.assign({}, utenteOrdine, addressOrdine, articoliOrdine);

            await orderService.putOrder(paramsOrdine).then(response => {setPopUp({'open': true, 'message': response.message})});

            setIsLoading(false);
            await deleteCart()
            setTimeout(function() {history.push('/home')}, 7000);

        } catch (error) {
            console.log(error);
        }
    }

    let line_items = cart.addedProducts.map((product) => {
        return (
            <LineItem
                key={product.barcode.toString()}
                line_item={product}
                action_hidden = 'true'
            />
        );
    });

    let is_peso_variabile = cart.addedProducts.map((product) => { return product.is_peso_variabile})

    return(
        <div>
            {((isLoading) ? <Loading/> : null) }
            <PopUpOrdine PopUp={PopUp} setPopUp={setPopUp}/>
            <div style={divRecipe}>
                <div className="section_cart">
                    <div className="cart_box">
                        <div className="carrello">
                            <h2>Il tuo carrello</h2>
                            <div>
                                <ul className="Checkout__line-items">
                                    {line_items}
                                </ul>
                                <div className="Cart-info clearfix">
                                    <div className="Cart-info__total">Totale</div>
                                    <div className="Cart-info__pricing">
                                        <span className="pricing">
                                            €{cart.addedProducts.reduce(getSum,0).toFixed(2)} {(is_peso_variabile.indexOf(true) > -1) ? "*" : ''}
                                        </span>
                                    </div>
                                    <div style={{paddingTop:"30px", fontSize: "10px", float: "left"}}>
                                        <span>
                                             {(is_peso_variabile.indexOf(true) > -1) ? "*il prezzo potrebbe subire leggeri modifiche in aumento o diminuzione per effetto della pesata corretta dopo il prelievo a magazzino" : ''}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_right">
                    <div className="address_box">
                        <div className="indirizzo">
                            <h2>Il tuo dati per la spedizione</h2>
                            <div className="address-content">
                                <div>{shippingAddress.nome}&nbsp;{shippingAddress.cognome}</div>
                                <div>{shippingAddress.address}, {shippingAddress.house_nr}</div>
                                <div>{shippingAddress.phone}</div>
                                <div>{shippingAddress.email}</div>
								<div>{shippingAddress.note_aggiuntive}</div>
                            </div>
                        </div>
                    </div>
                    <div className="confirm_button_box">
                        <button style={ buttonStyleConfirm } onClick={ () => inviaOrdine() }>Invia Ordine</button>
                    </div>
                </div>
            </div>
            <div style={divButtonStyle}>
                <button style={ buttonStyle } onClick={ () => setStep(step - 1) }>Indietro</button>
            </div>
        </div>
    )
}

export default RenderCart
