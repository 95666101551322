import React from 'react';

export const FiltriIcons = ({filtro, setFiltro, classeSecco, setClasseSecco, classeFresco, setClasseFresco, classeCongelato, setClasseCongelato, setSelectedCategoryId, setSelectedSubCategory}) => {

    const handleClick = (tipo) => {
        if (tipo){
            setFiltro({...filtro,"types": [tipo]})
            switch (tipo) {
                case 'C':
                    {
                        setClasseCongelato("icon-item");
                        setClasseFresco("icon-item nascondi");
                        setClasseSecco("icon-item nascondi");
                        break;
                    }
                case 'S':
                {
                    setClasseSecco("icon-item");
                    setClasseFresco("icon-item nascondi");
                    setClasseCongelato("icon-item nascondi");
                    break;
                }
                case 'F':
                {
                    setClasseFresco("icon-item");
                    setClasseSecco("icon-item nascondi");
                    setClasseCongelato("icon-item nascondi");
                    break;
                }
                default:
                    setClasseFresco("icon-item");
                    setClasseSecco("icon-item ");
                    setClasseCongelato("icon-item ");
            }
        }
        else{
            setFiltro({});
            setClasseCongelato("icon-item");
            setClasseFresco("icon-item");
            setClasseSecco("icon-item");
            setSelectedCategoryId('');
            setSelectedSubCategory('');
        }
    };


    return (
        <>
        <button className="azzeraFiltro" onClick={() => handleClick('')}>
            <i className="fa fa-times"><span style={{fontSize:"16px"}}> Pulisci filtri</span></i>
        </button>
        <div className="icon-list">
            <div className={classeCongelato} onClick={() => handleClick('C')}>
                <img src="assets/images/congelato.png" alt="congelato" style={{verticalAlign: "bottom"}}/>
            </div>
            <div className={classeFresco} onClick={() => handleClick('F')}>
                <img src="assets/images/fresco.png" alt="fresco" style={{verticalAlign: "bottom"}}/>
            </div>
            <div className={classeSecco} onClick={() => handleClick('S')}>
                <img src="assets/images/sole.png" alt="secco" style={{verticalAlign: "bottom"}}/>
            </div>
        </div>
        </>
    )
};
