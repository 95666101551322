import React from "react";

export function Condizioni(){
    return (
        <div>
            <div className="content product-detail">
                <div className="container-fluid">
                    <div className="row">
                        <div className="detail-content">
                            <h2 style={{paddingBottom: "30px"}}>CONDIZIONI GENERALI DI VENDITA:</h2>
                            <ul style={{listStyle: "Decimal", paddingLeft:"18px"}}>
                                <li>L&rsquo;indirizzo, che appare sul presente documento verrà utilizzato agli effetti della legge sulla privacy 2016/679. Con questo avviso ci consideriamo esonerati da qualsiasi responsabilità..</li>
                                <li>La ditta non risponde del calo peso naturale della merce. La merce viaggia a rischio e pericolo del committente anche se spedita franco destino..</li>
                                <li>Non si accettano reclami a nessun titolo trascorsi 2 giorni dal ricevimento della merce..</li>
                                <li>Il pagamento dei prodotti a peso variabile risulta certo, solo quando avviene la misurazione del peso corretto in fase di preparazione dell&rsquo;ordine..</li>
                                <li>I resi merce devono essere fatti contestualmente alla consegna dell&rsquo;ordine, trascorso tale termine la merce si intende vista e piaciuta.</li>
                                <li>Tutte le contestazioni per ammanchi di merce verranno accettate solo se annotate su documento accompagnatorio controfirmato dall’autista addetto alla consegna..</li>
                                <li>Le merci congelate sono state consegnate a -18°C, quelle fresche a +2°C. La firma del destinatario lo conferma..</li>
                                <li>Confezioni originali e non frazionabili..</li>
                                <li>MODALITA’ DI CONSERVAZIONE DEI PRODOTTI CONGELATI: nel frigorifero 24 ore. Nel freezer 3 giorni. A -18°C per lungo tempo, preferibilmente non oltre 18 mesi dalla data di congelamento risultante dalla etichetta della merce o dalla documentazione commerciale. Istruzioni per l&rsquo;uso: scongelare e consumare secondo la normativa vigente (art. 3 e 12 D.P.R. 18.5.1982 n.332)..</li>
                                <li>Il pagamento delle forniture eseguite da ITARCA SPA. per essere liberatorio deve avvenire in contrassegno, tramite contante o l&rsquo;utilizzo del POS in dotazione all&rsquo;autista.</li>
                                <li>La merce rimane di proprietà di ITARCA SPA. fino a completo pagamento; la proprietà è riservata anche se venduta.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
