import React, { useState } from 'react';
import {Address} from "./Address/Address";

function AddressContainer(props){

	let i_id = ((props.address) ? props.address.id : '');
	let i_nome = ((props.address) ? props.address.nome : '');
    let i_cognome = ((props.address) ? props.address.cognome : '');
    let i_address = ((props.address) ? props.address.address : '');
    let i_address_state = ((props.address && props.address.state) ? props.address.state : '');
    let i_address_region = ((props.address && props.address.region) ? props.address.region : '');
    let i_address_province = ((props.address && props.address.province) ? props.address.province : '');
    let i_address_city = ((props.address && props.address.city) ? props.address.city : '');
    let i_address_cap = ((props.address && props.address.cap) ? props.address.cap : '');
    let i_address_street = ((props.address && props.address.street) ? props.address.street : '');

    let i_phone = ((props.address && props.address.phone) ? props.address.phone : "");
    let i_house_nr = ((props.address) ? props.address.house_nr : '');
	let i_note_aggiuntive = ((props.address) ? props.address.note_aggiuntive : '');
    let i_is_shipping_address = ((props.address) ? props.address.is_shipping_address : false);

    const [disabled, setDisabled] = useState(true);

    const [formAddress, setFormAddress] = useState({
        'fields': {
			id: i_id,
            nome: i_nome,
            cognome: i_cognome,
            address: i_address,
            phone: i_phone,
            house_nr: i_house_nr,
			note_aggiuntive: i_note_aggiuntive,
            is_shipping_address: i_is_shipping_address,
            state: i_address_state,
            region:i_address_region,
            province: i_address_province,
            city: i_address_city,
            cap:i_address_cap,
            street: i_address_street
        },
        'error': {
            nome: '',
            cognome: '',
            email: '',
            address: '',
            phone: '',
            house_nr: '',
			note_aggiuntive: ''
        }
    });

    return (
        <Address disabled={disabled} setDisabled={setDisabled}
                 search={props.search}
                 setSearch={props.setSearch}
                 putAddress={props.putAddress} deleteAddress={props.deleteAddress}
                 formAddress={formAddress} setFormAddress = {setFormAddress}
                 id={props.id} fromCheckout={props.fromCheckout}
                 shippingAddress={props.shippingAddress}
                 setShippingAddress={props.setShippingAddress}
                 address={props.address}
                 setAddress={props.setAddress}
                 fetchAddress={props.fetchAddress}/>
    )
}

export default AddressContainer;
