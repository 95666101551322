import React from 'react';
import './productDetail.css';
import {Link} from "react-router-dom";
import Slider from "react-slick";
import {baseUrlImages} from "../../../shared/baseUrl";

export function ProductDetail(props) {
    var settings = {
        customPaging: function(i) {
            return (
                <button>
                    <img src={baseUrlImages + `${props.product.images[i].url}`} alt={`${props.product.name}`}/>
                </button>
            );
        },
        arrows:false,
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    var images = props.product.images;

    return (
        <div>
            <ul className="breadcrumb">
                <li><Link to="/home">Home</Link></li>
                <li><Link to={{pathname:"/products", state:{categories: {value : [props.product.category.id] , label: props.product.category.label}}}}>{props.product.category.label}</Link></li>
                <li><Link to={{pathname:"/products", state:{categories: {value : [props.product.category.id] , label: props.product.category.label},
                                                            sub_categories: {value : [props.product.sub_category.id] , label: props.product.sub_category.label}}
                                                        }}>{props.product.sub_category.label}</Link></li>
                <li>{props.product.name}</li>
            </ul>
            <div className="content product-detail">
                <div className="container-fluid">
                    <div className="row">
                        <div className="slider_box">
                            <Slider {...settings}>
                                {
                                    images.map((image, index) => (
                                        <div key={index}>
                                            <img src={baseUrlImages + image.url} className="react-slick-detail-image" alt={props.product.name}/>
                                        </div>
                                    ))
                                }
                            </Slider>
                        </div>
                        <div className="detail-content">
                            <h1>{props.product.name}</h1>
                            <p>
                                {props.product.description}
                            </p>
                            <p>
                                {props.product.additional_notes}
                            </p>
                            <p>
                                €{props.product.unit_price.toFixed(2)}
                            </p>
                            <div className="shop detail">
                                <div className="qta-buttons buttons-detail">
                                    <button id="login_button" className="qta-buttons-item qta-buttons-item-minus" onClick={() => props.setQuantity(props.quantity - 1)
                                    }>
                                        <i className="fa fa-minus"></i>
                                    </button>
                                    <button id="login_button" className="qta-buttons-item qta-buttons-item-plus" onClick={() => props.setQuantity(props.quantity + 1)}>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </div>
                                <div className="qta qta-detail">
                                    <span className="qta-item-input qta-item-input-detail">{props.quantity}</span>
                                </div>
                                <div className="total total-detail">
                                    <button id="cart_button" className="cart-button" onClick={() => props.handleClickAddToCart(props.product, props.quantity)}>
                                        <div className="total-item total-item-cart">
                                            <i className="fa fa-shopping-cart"></i>
                                        </div>
                                        <div className="total-item">
                                            € {(props.product.unit_price*props.quantity).toFixed(2)}
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <p>
                                {props.product.description}
                            </p>
							<div className="note-content">
								{props.product.is_peso_variabile && <p className="note-row"><span className="note-row-asterisco">*</span> Il prezzo potrebbe subire leggeri modifiche in aumento o diminuzione per effetto della pesata corretta dopo il prelievo a magazzino</p>}
								{props.product.coming_soon && <p className="note-row"><span className="note-row-asterisco">*</span> Il prodotto sarà disponibile a breve</p>}
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )};
