import React, {useEffect} from 'react';
import {ListProducts} from "./ListProducts/ListProducts";
import {productsActions} from "../../../redux/product/ActionCreators";
import {connect} from "react-redux";
import {Loading} from "../../LoadingComponent";
import ProductFilterContainer from "../ProductFilterContainer/ProductFilterContainer";

function ListProductsContainer({products,
                                   filtro,
                                   setFiltro,
                                   fetchProducts,
                                   location,
                                   categories,
                                   selectedCategoryId,
                                   setSelectedCategoryId,
                                   selectedSubCategory,
                                   setSelectedSubCategory,
                                   classeSecco,
                                   setClasseSecco,
                                   classeFresco,
                                   setClasseFresco,
                                   classeCongelato,
                                   setClasseCongelato
                               }){

    useEffect(() => {
        if (location.state && location.state !== filtro)
        {
            if (location.state.categories || location.state.sub_categories)
            {
                var filtro_categorie;
                if (location.state.categories && !location.state.sub_categories ){
                    filtro_categorie = {'categories': location.state.categories.value}
                    setSelectedCategoryId(location.state.categories)
                    setSelectedSubCategory('')
                }
                if (location.state.sub_categories){
                    filtro_categorie ={
                                        'categories': location.state.categories.value,
                                        'sub_categories' : location.state.sub_categories.value
                                        }
                    setSelectedCategoryId(location.state.categories)
                    setSelectedSubCategory(location.state.sub_categories)
                }
                setFiltro(filtro_categorie)
            }
            else
                setFiltro(location.state);
            location.state = ''
        }
        else{
            fetchProducts(filtro);
        }

    }, [filtro, fetchProducts, location.state, setFiltro, setSelectedCategoryId, setSelectedSubCategory])

    var search = (filtro.search_string ) ? filtro.search_string : '';

    if (products.isLoading)
        return <Loading/>
    else
    {
        return (
            <>
                <ProductFilterContainer categories={categories} filtro={filtro}
                                        setFiltro={setFiltro}
                                        selectedCategoryId={selectedCategoryId}
                                        setSelectedCategoryId = {setSelectedCategoryId}
                                        selectedSubCategory={selectedSubCategory}
                                        setSelectedSubCategory= {setSelectedSubCategory}
                                        classeSecco = {classeSecco}
                                        setClasseSecco = {setClasseSecco}
                                        classeFresco = {classeFresco}
                                        setClasseFresco = {setClasseFresco}
                                        classeCongelato = {classeCongelato}
                                        setClasseCongelato = {setClasseCongelato}
                />
                <div className="search-string">{(search) ? <p>Di seguito i risultati per la ricerca &quot;{search}&quot;... </p> : "" }</div>
                <div className="catalogo">
                    {(products.products) ? <ListProducts products={products.products}/> : <p style={{margin:"auto"}}>Nessun risultato trovato</p>}
                </div>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        products: state.products
    }
};

const mapDispatchToProps = dispatch => ({
    fetchProducts: (params) => { dispatch(productsActions.fetchProducts(params))},
});

export default connect(mapStateToProps, mapDispatchToProps)(ListProductsContainer);
