import * as ActionTypes from './ActionTypes';

const initialState = {
    addedProducts: [],
};
const CartReducer = (state = initialState, action) => {

    switch (action.type) {
        //utilizzare nel prodotto
        case ActionTypes.ADD_TO_CART: {
            var product = action.payload.product;
            var existed_item= state.addedProducts.find(item=> product.barcode === item.barcode);
            var quantity = action.payload.quantity;

            if(existed_item){
                existed_item.quantity += quantity
                return {...state}
            }
            else
            {
                product.quantity = quantity;
                return {...state, addedProducts: state.addedProducts.concat(product)};
            }

        }

        case ActionTypes.DELETE_CART:{
            return{
                ...state,
                addedProducts: []
            }
        }

        case ActionTypes.REMOVE_FROM_CART:{
                let new_items = state.addedProducts.filter(item=> action.barcode !== item.barcode)

                return{
                    ...state,
                    addedProducts: new_items
                }
        }

        case ActionTypes.ADD_QUANTITY:{
            let addedItem = state.addedProducts.find(item=> item.barcode === action.barcode)
            addedItem.quantity += 1

            return{
                ...state
            }
        }

        case ActionTypes.SUB_QUANTITY:{
            let addedItem = state.addedProducts.find(item=> item.barcode === action.barcode)
            //if the qt == 0 then it should be removed
            if(addedItem.quantity === 1){
                let new_items = state.addedProducts.filter(item=>item.barcode !== action.barcode)

                return{
                    ...state,
                    addedProducts: new_items
                }
            }
            else {
                addedItem.quantity -= 1

                return{
                    ...state
                }
            }

        }

        default:
            return state;
    }
};
export {CartReducer};
