export const informationService = {
    getInformation,
    setInformation
};

function getInformation(params) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    };

    return fetch('https://shop.itarca.com/api/get_info_utente', requestOptions)
        .then(response => response.json())
        .catch(error =>  { console.log('get_info_utente', error.message); });
}


function setInformation(params) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    };

    return fetch('https://shop.itarca.com/api/set_info_utente', requestOptions)
        .then(response => response.json())
        .catch(error =>  console.log('set_info_utente', error.message) );
}
