import React, { useState } from 'react';
import Checkout from "./Checkout/Checkout";
import {addToCart, deleteCart} from "../../../redux/cart/ActionCreators";
import {connect} from "react-redux";

function CheckoutContainer(props){

    const [step, setStep] = useState(0);
    const [shippingAddress, setShippingAddress] = useState({
        state: '',
        region: '',
        province: '',
        city: '',
        cap: '',
        street: '',
        house_nr: '',
		note_aggiuntive: '',
        nome: '',
        cognome: '',
        address:'',
        phone: 0,
        email:''
    });

    const [formAddress, setFormAddress] = useState({
        'fields': {
            nome: '',
            cognome: '',
            email: '',
            address: '',
            phone: '',
            house_nr: '',
			note_aggiuntive: '',
        },
        'error': {
            nome: '',
            cognome: '',
            email: '',
            address: '',
            phone: '',
            house_nr: '',
			note_aggiuntive: '',
        }
    });

    return (
        <Checkout shippingAddress={shippingAddress}
                  setShippingAddress={setShippingAddress}
                  formAddress={formAddress}
                  setFormAddress={setFormAddress}
                  addresses={props.addresses}
                  authentication={props.authentication}
                  cart={props.cart}
                  step={step}
                  setStep={setStep}
                  deleteCart={props.deleteCart}/>
    )
}

const mapStateToProps = state => {
    return {
        cart: state.cart,
        authentication: state.authentication,
        addresses: state.addresses.addresses,
    }
};

const mapDispatchToProps = dispatch => ({
    addToCart: (product, quantity) => dispatch(addToCart(product, quantity)),
    deleteCart: () => dispatch(deleteCart())
});


export default connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer);
