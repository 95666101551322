import React, {useEffect, useState} from 'react';
import {Information} from "./Information/Information";
import {connect} from "react-redux";
import {actions} from "react-redux-form";
import {Loading} from "../../LoadingComponent";
import {informationService} from "../../../services/information.service";
import {create_md5} from "../../../helpers/auth-header";

function InformationContainer({setDefaultFormValues, resetFormInfomartion}){
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let unmounted = false;

        var users = JSON.parse(sessionStorage.getItem('user'))
        var params = {
            "indirizzo_email": users.indirizzo_email,
            "psw_md5": users.psw_md5,
            "id_session": users.id_session
        }

        setIsLoading(true)

        const fetchData = async () => {
            try {
                informationService.getInformation(params)
                    .then(response => response.utente)
                    .then(information => {
                        if (!unmounted) {
                            setIsLoading(false)
                            setDefaultFormValues({
                                nome: information.nome,
                                cognome: information.cognome,
                                email: information.indirizzo_email,
                                password: '',
                                confirmPassword: ''
                            })
                        }
                    })
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
        return () => { unmounted = true };
    }, [setDefaultFormValues]);

    //sistemare chiamata

    const setInformation = async (information) => {
        try {
            setIsLoading(true);
            var users = JSON.parse(sessionStorage.getItem('user'))

            var params_user = {
                "indirizzo_email": users.indirizzo_email,
                "psw_md5": users.psw_md5,
                "id_session": users.id_session
            };

            var params_utente={
                "utente" :{
                    "nome": information.nome,
                    "cognome": information.cognome,
                    "check_newsletter": false,
                    "psw_md5": ((information.password) ? create_md5(information.password): users.psw_md5)
                }
            };

            var params = Object.assign({}, params_user, params_utente);

            await informationService.setInformation(params)
                .then(response => response.utente)
                .then(() => {

                    var params_user = {
                        "indirizzo_email": users.indirizzo_email,
                        "psw_md5": ((information.password) ? create_md5(information.password): users.psw_md5),
                        "id_session": users.id_session
                    };
                    sessionStorage.setItem('user', JSON.stringify(params_user));
                    informationService.getInformation(params_user)
                        .then(response => response.utente)
                        .then(information => {
                            setIsLoading(false)
                            setDefaultFormValues({
                                nome: information.nome,
                                cognome: information.cognome,
                                email: information.indirizzo_email
                            })
                        })
                    }
                )
        } catch (error) {
            console.log(error);
        }
    };

    if (isLoading)
    {
        return <Loading/>
    }
    else
    {
        return (
            <Information
                      alert={alert}
                      resetFormInfomartion={resetFormInfomartion}
                      setInformation={setInformation}/>
        )
    }
}

const mapStateToProps = state => {
    return {
        alert: state.alert,
        information: state.forms.information
    }
};

const mapDispatchToProps = dispatch => ({
    resetFormInfomartion: () => { dispatch(actions.reset('information'))},
    setDefaultFormValues: (values) => dispatch((actions.merge('information', values)))
});


export default connect(mapStateToProps, mapDispatchToProps)(InformationContainer);
