import React, {useState} from 'react';
import {User} from "./User/User";
import { userActions } from '../../../redux/user/ActionCreators';
import {connect} from "react-redux";

function UserContainer(props){

    const [component, setComponent] = useState('Information');


    return (
        <User component={component} setComponent={setComponent} logout={props.logout} loggingIn={props.loggingIn}/>
    )
}


const mapStateToProps = state => {
    return {
        loggingIn: state.authentication
    }
};

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(userActions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
