import React from 'react';

export default function PopUpOrdine({PopUp, setPopUp}) {
    var classNameState = ((PopUp.open) ? 'modal father modal_open' : 'modal father modal_close')

    return (
        <div className={classNameState}>
            <div className="modal" style={{width:"300px", height:"auto", borderRadius: "10px", position:"static", padding:"30px"}}>
                <button className="Order_close" onClick={() => setPopUp({'open': false})}>
                    <i className="fa fa-times"></i>
                </button>
                {PopUp.message}
            </div>
        </div>
    );

}
