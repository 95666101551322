export const categoryService = {
    getCategories
};

function getCategories() {
    const requestOptions = {
        method: 'GET'
    };

    return fetch('https://shop.itarca.com/api/get_categorie', requestOptions)
        .then(response => response.json())
        .catch(error =>  { console.log('get orders', error.message); });
}
