import * as ActionTypes from './ActionTypes';
import {productService} from "../../services";


export const productsActions = {
    fetchProducts
};


function productsLoading () {
    return {
        type: ActionTypes.PRODUCTS_LOADING
    }
}

function productsFailed (errmess) {
    return {
        type: ActionTypes.PRODUCTS_FAILED,
        payload: errmess}
}

function addProducts (products) {
    return {
        type: ActionTypes.ADD_PRODUCTS,
        payload: products
    }
}

function fetchProducts (params) {
    return dispatch => {
            dispatch(productsLoading());
            productService.getProducts(params)
                .then(response => response.articles)
                .then(products => dispatch(addProducts(products)))
                .catch(error => dispatch(productsFailed(error.message)));
    };
}
