import * as ActionTypes from './ActionTypes';

//product component
export const addToCart = (product, quantity) => ({
    type: ActionTypes.ADD_TO_CART,
    payload: {
        product: product,
        quantity: quantity
    }
});

//delete cart
export const deleteCart = () => ({
    type: ActionTypes.DELETE_CART,
    payload: {
    }
});

//cart component
export const removeFromCart = (barcode) => ({
    type: ActionTypes.REMOVE_FROM_CART,
    barcode: barcode
});


export const addQuantity = (barcode) => ({
    type: ActionTypes.ADD_QUANTITY,
    barcode: barcode

});

export const subQuantity = (barcode) => ({
    type: ActionTypes.SUB_QUANTITY,
    barcode: barcode
});
