import React, {useEffect} from 'react';

export default function ControlledPopup({PopUp, setPopUp}) {

    var classNameState = ((PopUp.open) ? 'modal father modal_open' : 'modal father modal_close')

    let visited = sessionStorage["alreadyVisited"];

    useEffect(() => {
        if(visited) {
            setPopUp({ 'open': false})
        } else {
            setPopUp({ 'open': true})
        }

        return () => sessionStorage["alreadyVisited"] = true
    },[setPopUp, visited]);

    return (
        <div className={classNameState}>
            <div className="modal" style={{width:"300px", height:"auto", borderRadius: "10px", position:"static", padding:"30px"}}>
                <button className="Order_close" onClick={() => setPopUp({'open': false})}>
                    <i className="fa fa-times"></i>
                </button>
                La consegna è attiva soltanto nelle province di <strong>Parma, Reggio e Modena</strong>.<br/>
                L&rsquo;ordine minimo è di 100€ e la spedizione è sempre gratuita.<br/>
                Per il ritiro in sede contattarci al 0522-673090
            </div>
        </div>
            );

}
