import React from 'react';
import './App.css';
import Main from './components/Main/MainComponent';
import {Router} from "react-router-dom";
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';

import {history} from "./helpers/history";

const store = ConfigureStore();

function App() {
  return (
      <Provider store={store}>
          <Router history={history}>
            <div className="App">
                <Main />
            </div>
        </Router>
      </Provider>
);
}

export default App;
