import { authHeader } from '../helpers/auth-header';

export const orderService = {
    getOrders,
    putOrder
};

function getOrders(params) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
    };

    return fetch('https://shop.itarca.com/api/get_ordini', requestOptions)
        .then(response => response.json())
        .catch(error =>  { console.log('get orders', error.message); });
}

function putOrder(params) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
    };

    return fetch('https://shop.itarca.com/api/put_ordine', requestOptions)
        .then(response => response.json())
        .catch(error =>  { console.log('put_ordine', error.message); });
}
