import {
	authHeader,
	create_md5,
	stacca_sid
} from '../helpers/auth-header';

export const userService = {
	login,
	logout,
	register,
	update,
	delete: _delete,
	reset
};

//Aggiungere passaggio del token
function login(mail, password) {
	var psw_md5 = create_md5(password);
	var sid = stacca_sid(32);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			"indirizzo_email": mail,
			"psw_md5": psw_md5,
			"sid": sid
		})
	};
	return fetch(`https://shop.itarca.com/api/login`, requestOptions)
		.then(handleResponse)
		.then(response => {
			var user = {
				"indirizzo_email": mail,
				"psw_md5": create_md5(password),
				"id_session": response.session_id
			}
			// store user details and jwt token in local storage to keep user logged in between page refreshes
			sessionStorage.setItem('user', JSON.stringify(user));

			sessionStorage.setItem('sid', sid);

			return user;
		});
}

function logout() {
	// remove user from local storage to log user out
	sessionStorage.removeItem('user');
}

function register(user) {
	var psw_md5 = create_md5(user.password);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			"nome": user.nome,
		    "cognome": user.cognome,
		    "indirizzo_email": user.mail,
		    "psw_md5": psw_md5,
		    "check_condizioni": user.checkCondizioni,
		    "check_privacy": user.checkPrivacy,
		    "check_newsletter": false
		})
	};

	return fetch(`https://shop.itarca.com/api/register`, requestOptions)
		.then(handleResponse)
}

function update(user) {
	const requestOptions = {
		method: 'PUT',
		headers: {
			...authHeader(),
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(user)
	};

	return fetch(`/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(`/users/${id}`, requestOptions).then(handleResponse);
}

function reset(email) {

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			"indirizzo_email": email,
		})
	};

	return fetch(`https://shop.itarca.com/api/reset_password`, requestOptions)
		.then(handleResponse)
}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				logout();
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}

