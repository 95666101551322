import React from 'react';
import Categories from '../../CategoriesContainer/CategoriesContainer'
import ListProduct from '../../SliderProductsContainer/SliderProductsContainer'
import './home.css'
import ControlledPopup from "../../../PopUpSpedizioni";
import {Link} from "react-router-dom";

export function Home({PopUp, setPopUp}) {
	return (
		<div className="content">
			<div className="image-central">
				<h1>Il meglio dei Prodotti alimentari <br/>direttamente a casa tua</h1>
				<Link to="/products">
					<div className="bottone-catalogo">SCOPRILI ORA!</div>
				</Link>
			</div>
			<div className="home-section container-fluid-big">
				<div className="section-title">Articoli in evidenza</div>
				<ListProduct/>
			</div>
			<div className="container-fluid-big">
				<div className="section-title"></div>
				<Categories/>
			</div>
			<ControlledPopup PopUp={PopUp} setPopUp={setPopUp}/>
		</div>
	)};
