import React, {useEffect} from 'react';
import {SliderProducts} from "./SliderProducts/SliderProducts";
import {productsActions} from "../../../redux/product/ActionCreators";
import {useDispatch, useSelector} from "react-redux";

export default function SliderProductsContaine(){

    const products = useSelector((state) => state.products);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(productsActions.fetchProducts({'items_per_page': 8}));
    },[dispatch]);

    if (!products.products){
        products.products = []
    }

    return (
        <div>
            <SliderProducts products={products.products}/>
        </div>
    )
}
