import React from 'react';
import Select from "react-select";

export const DropDownCategories = ({filtro, setFiltro, categories, setSelectedCategoryId, selectedCategoryId, setSelectedSubCategory}) => {

    if (categories){

        const options = [];

        options.push({value:'', label:'Tutte...'})
        for (const [key, value] of Object.entries(categories)) {
            options.push({value:key, label:value.name})}

        const handleChange = selectedOption => {
            setSelectedCategoryId(selectedOption)
            setSelectedSubCategory('')
            if (selectedOption.value){
                setFiltro(
                    {...filtro,'categories': [parseInt(selectedOption.value)]}
                )
            }
            else{
                setSelectedSubCategory('')
                setFiltro({...filtro,'categories':[], 'sub_categories': []})
            }
        };

        return(
                <Select
                    value={selectedCategoryId}
                    options={options}
                    onChange={handleChange}
                    placeholder="Seleziona..."
                />
        )
    }
    else {
        return(
            <Select
                placeholder="Seleziona..."
            />
        )
    }
};
