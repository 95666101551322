export const productService = {
    getProducts
};

function getProducts(params) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    };

    return fetch('https://shop.itarca.com/api/get_articoli', requestOptions)
        .then(response => response.json())
        .catch(error =>  { console.log('get_articoli', error.message); });
}
