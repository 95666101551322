import { PRODUCTS_LOADING, ADD_PRODUCTS, PRODUCTS_FAILED } from './ActionTypes';

const initialState = {
    isLoading: true,
    errMess: null,
    products: []
};

const ProductReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_PRODUCTS:
            return {...state, isLoading: false, errMess: null, products: action.payload};

        case PRODUCTS_LOADING:
            return {...state, isLoading: true, errMess: null, products: []};

        case PRODUCTS_FAILED:
            return {...state, isLoading: false, errMess: action.payload, products: []};

        default:
            return state;
    }
}

export {ProductReducer};
