import React from 'react';
import { history } from '../../../../helpers/history';
import  './user.css';
import $ from 'jquery';

import Information from "../../InformationContainer/InformationContainer";
import ListAddress from "../../ListAddressContainer/ListAddressContainer";
import ListOrders from "../../ListOrdersContainer/ListOrdersContainer";

export function User(props) {

    const handleClickLogout = () => {
        props.logout();
        history.push('/login')
    };

    const RenderComponent = () => {
		let { component } = props;
		switch (component){
			case 'information':
				return <Information />;
			case 'address':
				return <ListAddress />;
			case 'order':
				return <ListOrders />
			default:
				return <Information />;
		}
	}

	const handleClickMenu = (nameComponent) => {
		props.setComponent(nameComponent);
	};

	$('#root').on('click', '.user_menu_voice', function() {
		$('.user_content').attr('data-hidden', 'false');
		$('.user_menu').attr('data-hidden', 'true');
	});

	$('#root').on('click', '.go_back_button', function() {
		$('.user_content').attr('data-hidden', 'true');
		$('.user_menu').attr('data-hidden', 'false');
	});

    return (
			<div className="user_page">
				<div className="user_menu" data-hidden="false">
					<div className="user_menu_content">
						<div className="user_menu_voice" onClick={() => handleClickMenu()}>
							<i className="fa fa-info"></i>Le mie informazioni
						</div>

						<div className="user_menu_voice" onClick={() => handleClickMenu('address')}>
							<i className="fa fa-address-book"></i>Gestisci indirizzi
						</div>

						<div className="user_menu_voice" onClick={() => handleClickMenu('order')}>
							<i className="fa fa-shopping-cart"></i>I miei ordini
						</div>
					</div>

					<div className="user_menu_footer">
						<div className="user_menu_voice" onClick={() => handleClickLogout()}>
							<i className="fa fa-sign-out"></i>Esci
						</div>
					</div>
				</div>

				<div className="user_content" data-hidden="true">
					<div className="user-content-header go_back_button">
						<i className="fa fa-arrow-left"></i>Torna indietro
					</div>

					<RenderComponent />

				</div>
			</div>
        )
}
