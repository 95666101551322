import React, {useEffect, useState} from 'react';
import Footer from '../Footer/Footer';
import Header from "../HeaderContainer/HeaderContainer";
import Home from "../pages/HomeContainer/HomeContainer"
import ListProducts from "../pages/ListProductsContainer/ListProductsContainer";
import ProductDetail from "../pages/ProductDetailContainer/ProductDetailContainer";
import {Switch, Route, Redirect, withRouter} from "react-router-dom";
import './main.css';
import {PrivateRoute} from "../shared/PrivateRoute";
import User from "../pages/UserContainer/UserContainer";
import LoginPage from "../pages/LoginContainer/LoginContainer";
import Register from "../pages/RegisterContainer/RegisterContainer";
import ResetPassword from "../pages/ResetPasswordContainer/ResetPasswordContainer";
import Checkout from "../pages/CheckoutContainer/CheckoutContainer";
import Ordine from "../pages/DetailOrderContainer/DetailOrderContainer";
import {Condizioni} from "../Condizioni";
import {Spedizioni} from "../Spedizioni";
import {categoryService} from "../../services";

function Main(props){
    const [filtro, setFiltro] = useState({});
    const [categories, setCategories] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState({});
    const [selectedSubCategory, setSelectedSubCategory] = useState({});
    const [classeSecco, setClasseSecco] = useState('icon-item');
    const [classeFresco, setClasseFresco] = useState('icon-item');
    const [classeCongelato, setClasseCongelato] = useState('icon-item');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await categoryService.getCategories();
                setCategories(res.categories)
            } catch (error) {
                alert(error);
            }
        };
        fetchData();
    }, []);

    const ProductWithId = ({match}) => {
        return (
            <ProductDetail barcode={match.params.productId}/>
        )
    };

    return(
        <div className="App-content">
            <Header/>
                <Switch>
                    <Route path="/home" component={Home}/>
                    <Route path="/products" component={() => <ListProducts
                        location={props.location}
                        filtro={filtro}
                        setFiltro={setFiltro}
                        categories={categories}
                        selectedCategoryId={selectedCategoryId}
                        setSelectedCategoryId = {setSelectedCategoryId}
                        selectedSubCategory={selectedSubCategory}
                        setSelectedSubCategory= {setSelectedSubCategory}
                        classeSecco = {classeSecco}
                        setClasseSecco = {setClasseSecco}
                        classeFresco = {classeFresco}
                        setClasseFresco = {setClasseFresco}
                        classeCongelato = {classeCongelato}
                        setClasseCongelato = {setClasseCongelato}

                    />
                    }/>
                    <PrivateRoute exact path="/user" component={User} />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/register" component={Register} />
                    <Route path="/resetPassword" component={ResetPassword} />
                    <Route exact path="/product/:productId" component={ProductWithId} />
                    <Route path="/checkout" component={Checkout} />
					<Route path="/ordine" component={Ordine} />
                    <Route path="/condizioni" component={Condizioni} />
                    <Route path="/spedizioni" component={Spedizioni} />

                    <Redirect to="/home" />
                </Switch>
            <Footer/>
        </div>
    )
}

export default withRouter(Main);

