import React from 'react';
import './detailorder.css';
import {OrderProduct} from "./OrderProductContainer/OrderProduct/OrderProduct";

var ORDER_STATE = {
	"creato": "ATTESA CONFERMA",
	"pagato": "IN PREPARAZIONE",
	"spedito": "SPEDITO",
	"consegnato": "CONSEGNATO"
}

// var props.order = {
// 	"id": 456897,
// 	"date_start": "2020-01-01",
// 	"state": "spedito",
// 	"address": {
// 		"street": "Via Fll. Cervi, 42",
// 		"city": "Reggio nell'Emilia",
// 		"provice": "RE",
// 		"cap": 42122,
// 		"state": "Italy"
// 	},
// 	"articoli": [{
// 		"name": "Garronese Lombata 3 Coste Fresca",
// 		"unit_meausure": "f.Garronese Lombata.3/C",
// 		"unit_price": 34.95,
// 		"qta": 1,
// 		"image_url": "/assets/images/arrosticini0.jpg"
// 	}, {
// 		"name": "Garronese Lombata 3 Coste Fresca",
// 		"unit_meausure": "f.Garronese Lombata.3/C",
// 		"unit_price": 34.95,
// 		"qta": 3,
// 		"image_url": "/assets/images/arrosticini0.jpg"
// 	}]
// }


export function Ordine(props) {
	var classNameState = ((props.showModal) ? 'modal modal_open' : 'modal modal_close')

	var totale = 0
	var nr_articoli = 0;

	for (var i=0; i<props.order.articoli.length; i++) {
		totale += props.order.articoli[i]['unit_price'] * props.order.articoli[i]['qta'];
		nr_articoli +=  props.order.articoli[i]['qta'];
	}

	totale = totale.toFixed(2)

	return (
		<div>
		<button className="Order_close" onClick={() => props.setShowModal(false)}>
			<i className="fa fa-times"></i>
		</button>
		<div className={classNameState}>
			<div className="ordine">
				<div className="ordine_info">
					<div className="ordine_testata">
						<div className="ordine_dettagli">
							<div className="ordine_title">Dettagli ordine</div>
							<div className="ordine_testata_item_content">
								<div className="ordine_dettagli_item ordine_dettaglio_id">
									<div className="ordine_dettagli_title">Ordine nr.:</div>
									<div className="ordine_dettagli_value">{props.order.id}</div>
								</div>
								<div className="ordine_dettagli_item">
									<div className="ordine_dettagli_title">Nr. articoli:</div>
									<div className="ordine_dettagli_value">{nr_articoli}</div>
								</div>
								<div className="ordine_dettagli_item">
									<div className="ordine_dettagli_title">Data ordine:</div>
									<div className="ordine_dettagli_value">{props.order.date_start}</div>
								</div>
								<div className="ordine_dettagli_item">
									<div className="ordine_dettagli_title">Data arrivo:</div>
									<div className="ordine_dettagli_value">{(props.order.date_delivery !== undefined) ? props.order.date_delivery: "-"}</div>
								</div>

								<div className="ordine_dettagli_section">
									<div className="ordine_dettagli_section_title">Indirizzo spedizione</div>
									<div>{props.order.address.street}</div>
									<div>{props.order.address.city + ' (' + props.order.address.province + '), ' + props.order.address.cap}</div>
									<div>{props.order.address.state}</div>
								</div>
							</div>
						</div>

						<div className="ordine_avanzamento">
							<div className="ordine_title">Stato avanzamento ordine</div>
							<div className="ordine_avanzamento_box">
								<div className="ordine_avanzamento_slider">
									<div className={"ordine_avanzamento_slider_active " + props.order.state}></div>

									<div className="ordine_avanzamento_etichette">
										<div className="ordine_avanzamento_label">
											<div className="ordine_avanzamento_label_content">{ORDER_STATE.creato}</div>
										</div>

										<div className="ordine_avanzamento_label">
											<div className="ordine_avanzamento_label_content">{ORDER_STATE.pagato}</div>
										</div>

										<div className="ordine_avanzamento_label">
											<div className="ordine_avanzamento_label_content">{ORDER_STATE.spedito}</div>
										</div>

										<div className="ordine_avanzamento_label">
											<div className="ordine_avanzamento_label_content">{ORDER_STATE.consegnato}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="ordine_righe">
						<div className="ordine_title">Articoli presenti nell' ordine</div>
						<div className="ordine_righe_prodotti">
							{props.order.articoli.map(function(product){
                                return <OrderProduct product={product} />;
                            })}
						</div>
					</div>

					<div className="ordine_totale">
						<div className="ordine_totale_label">TOTALE</div>
						<div className="ordine_totale_value">€ {totale}</div>
					</div>
				</div>
			</div>
		</div>
		</div>
	);
}
