import React from "react";
import ListAddress from "../../ListAddressContainer/ListAddressContainer";
import {CheckoutAddressForm} from "./CheckoutAddressForm";

function RenderAddress(props) {

    const buttonStyle = {cursor: "pointer", fontSize: "16px", color:"#FFFFFF", background: '#0A6CA5', width: 200, padding: 16, textAlign: 'center', margin: '0 auto' };
    const divButtonStyle = {display:"flex", textAlign:"center", padding: "40px 40px 0 40px"};

    const required = (val) => val && val.length;
    const validEmail = (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);
    const validPhone = (val) => /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\/0-9]*$/i.test(val);

    const setError = (field, msg) => {
        props.setFormAddress((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields},
            error: {
                ...prevState.error, [field]: msg}
        }))
    }

    const handleStepClick = () => {
        var errore= false;
        if(!required(props.shippingAddress.nome))
            setError("nome",  'Input non valido')
        else
            setError("nome",  '')
        if(!required(props.shippingAddress.address))
            setError("address",  'Input non valido')
        else
            setError("address",  '')
        if(!required(props.shippingAddress.cognome))
            setError("cognome",  'Input non valido')
        else
            setError("cognome",  '')

        if(!required(props.shippingAddress.phone) || !validPhone(props.shippingAddress.phone))
            setError("phone",  'Input non valido')
        else
            setError("phone",  '')

        if(!required(props.shippingAddress.email) || !validEmail(props.shippingAddress.email))
            setError("email",  'Input non valido')
        else
            setError("email",  '')
        if(!required(props.shippingAddress.house_nr))
            setError("house_nr", 'Input non valido')
        else
            setError("house_nr",  '')

        if(
            !required(props.shippingAddress.nome) ||
            !required(props.shippingAddress.address) ||
            !required(props.shippingAddress.cognome)  || !required(props.shippingAddress.phone) ||
            !required(props.shippingAddress.email) || !validEmail(props.shippingAddress.email) ||
            !required(props.shippingAddress.house_nr)
        ){
            errore=true
        }
        if (!errore){
            props.setStep(props.step + 1)
        }

    }


    return (
        <div>
            <div className="row">
            {(props.authentication.loggedIn) ? (
                    <ListAddress fromCheckout="true"
                                 shippingAddress={props.shippingAddress}
                                 setShippingAddress={props.setShippingAddress}/>
                ) :
                <CheckoutAddressForm
                        formAddress = {props.formAddress}
                        setFormAddress = {props.setFormAddress}
                        shippingAddress={props.shippingAddress}
                        setShippingAddress={props.setShippingAddress}/>
            }
            </div>
            <div style={divButtonStyle}>
                <button style={ buttonStyle } onClick={ () => props.setStep(props.step - 1) }>Indietro</button>
                <button style={ buttonStyle } onClick={ () => handleStepClick() }>Avanti</button>
            </div>
    </div>)
}

export default RenderAddress;
